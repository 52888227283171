const response = {

  "2023-24": {

    "enrollmentsData": [
      {
        "totalAlumni": "4700",
        "corporatesonboard": "175",
        "Oman": "2573",
        "gulfCounties": "53,292",
        "Restoftheworld": "2,27,279",
        "graducate": "1692",
        "postgraduate": "247",
        "certification": "1057",
        "gfp": "529",
       
      },
    ],

    "series": [
      {
        
        size: '100%',
        "data": [
          {
            "name": "Employed",
            "y":  805,
            "color": "#008B35"
          },
          {
            "name": "Internships",
            "y": 3105,
            "color": "#657FE6"
          },
          {
            "name": "Internships with employment ",
            "y": 345,
            "color": "#3142A3"
          },
          {
            "name": "Not Placed",
            "y": 3795,
            "color": "#ff0000"
          },
        ]
      }
    ],

    "seriespie": [
      {
        innerSize:'50%',
        "data": [
          {
            "name": "Oman",
            "y":  2,
            "color": "#59BFEC"
          },
          {
            "name": "Gulf",
            "y": 34.4,
            "color": "#3142A3"
          },
          {
            "name": "Rest of the World",
            "y": 62.6,
            "color": "#75D284"
          },
        ]
      }
    ],
    
    "enrolmentsOverall": [
      {
        "name": "Achieved",
        "data": [4450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],

    "enrolmentsMinistry": [
      {
        "name": "Achieved",
        "data": [1750, 1936, 2529, 2248,3308]
      },
      {
        "name": "Targeted",
        "data": [2700, 2904, 2701, 3372,2702]
      }
    ],
    "enrolmentsDirect": [
      {
        "name": "Achieved",
        "data": [2700, 2904, 2701, 3372,2702]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],


    "employabilityMixyear2023pie": [
      {
        "data": [
          {
            "name": "Oil & Gas",
            "y": 15,
            "color":"#3142A3"
          },
          {
            "name": "Banking & Finance",
            "y": 10,
            "color":"#008B35"
          },
          {
            "name": "Telecommunications",
            "y": 8,
            "color":"#E8B500"
          },
          {
            "name": "Manufacturing",
            "y": 12,
            "color":"#59BFEC"
          },
          {
            "name": "Logistics",
            "y": 5,
            "color":"#3142A3"
          },
          {
            "name": "Government",
            "y": 18,
            "color":"#3B6842"
          },
          {
            "name": "Construction & Infrastructure",
            "y": 10,
            "color":"#657FE6"
          },
          {
            "name": "Healthcare & Pharmaceuticals",
            "y": 7,
            "color":"#59A165"
          },
          {
            "name": "Information Technology (IT) Services",
            "y": 6,
            "color":"#75D284"
          },
          {
            "name": "Education & Training",
            "y": 4,
            "color":"#008B35"
          },
          {
            "name": "Others",
            "y": 5,
            "color":"#008B68"
          }
        ]
      }
    ],

    "employabilityMixyear2022pie": [
      {
        "data": [
          {
            "name": "Oil & Gas",
            "y": 15,
            "color":"#3142A3"
          },
          {
            "name": "Banking & Finance",
            "y": 10,
            "color":"#008B35"
          },
          {
            "name": "Telecommunications",
            "y": 8,
            "color":"#E8B500"
          },
          {
            "name": "Manufacturing",
            "y": 12,
            "color":"#59BFEC"
          },
          {
            "name": "Logistics",
            "y": 5,
            "color":"#3142A3"
          },
          {
            "name": "Government",
            "y": 18,
            "color":"#3B6842"
          },
          {
            "name": "Construction & Infrastructure",
            "y": 10,
            "color":"#657FE6"
          },
          {
            "name": "Healthcare & Pharmaceuticals",
            "y": 7,
            "color":"#59A165"
          },
          {
            "name": "Information Technology (IT) Services",
            "y": 6,
            "color":"#75D284"
          },
          {
            "name": "Education & Training",
            "y": 4,
            "color":"#008B35"
          },
          {
            "name": "Others",
            "y": 5,
            "color":"#008B68"
          }
        ]
      }
    ],

    "employabilityMixyear2021pie": [
      {
        "data": [
          {
            "name": "Oil & Gas",
            "y": 15,
            "color":"#3142A3"
          },
          {
            "name": "Banking & Finance",
            "y": 10,
            "color":"#008B35"
          },
          {
            "name": "Telecommunications",
            "y": 8,
            "color":"#E8B500"
          },
          {
            "name": "Manufacturing",
            "y": 12,
            "color":"#59BFEC"
          },
          {
            "name": "Logistics",
            "y": 5,
            "color":"#3142A3"
          },
          {
            "name": "Government",
            "y": 18,
            "color":"#3B6842"
          },
          {
            "name": "Construction & Infrastructure",
            "y": 10,
            "color":"#657FE6"
          },
          {
            "name": "Healthcare & Pharmaceuticals",
            "y": 7,
            "color":"#59A165"
          },
          {
            "name": "Information Technology (IT) Services",
            "y": 6,
            "color":"#75D284"
          },
          {
            "name": "Education & Training",
            "y": 4,
            "color":"#008B35"
          },
          {
            "name": "Others",
            "y": 5,
            "color":"#008B68"
          }
        ]
      }
    ],

    
    


    "conversionyear2023": [
      {
        "name": "Fulltime",
        "data": [810, 510, 435, 660,585,360,360]
      },
      {
        "name": "Internships",
        "data": [1615, 114, 86, 133,95,76,67]
      }
    ],


    "conversionyear2022": [
      {
        "name": "Fulltime",
        "data": [810, 510, 435, 660,585,360,360]
      },
      {
        "name": "Internships",
        "data": [1615, 114, 86, 133,95,76,67]
      }
    ],

    "conversionyear2021": [
      {
        "name": "Fulltime",
        "data": [810, 510, 435, 660,585,360,360]
      },
      {
        "name": "Internships",
        "data": [1615, 114, 86, 133,95,76,67]
      }
    ],
    
    "programWiseEnrollment": {

      "category":[
        'Diploma in Computer Science (Data Analytics)',
        'Master of Science in Cyber Security',
        'Bachelor of Science (Hons) in Logistics Management',
        'B.Eng. (Hons) Computer Engineering with pathway in Cybersecurity',
        'B.Eng. (Hons) Mechanical Engineering',
        'B.Eng. (Hons) Civil Engineering',
        'MSc Fintech',
        'MSc Cyber Security',
        'MSc Electronic Engineering',
        'MSc Construction Project and Cost Management',
      ],

      "data":[
        
        {   
            "name": "Fulltime",
            data: [90, 110,85, 80,50, 50,25, 50,60,60],
            color:"#3142A3",
          
          },
           {
            "name": "Internships",
            data: [125, 175,185, 100,75, 75,50, 80,100,150],
            color:"#59BFEC",
          },
          
        ]
    },



  },

  "2022-23": {

    "enrollmentsData": [
      {
        "totalAlumni": "312",
        "corporatesonboard": "30",
        "Oman": "45",
        "gulfCounties": "20",
        "Restoftheworld": "35",
        "graducate": "20",
        "postgraduate": "40",
        "certification": "51",
        "gfp": "71",
       
      },
    ],

    "series": [
      {
        
        size: '100%',
        "data": [
          {
            "name": "Employed",
            "y":  805,
            "color": "#008B35"
          },
          {
            "name": "Internships",
            "y": 3105,
            "color": "#657FE6"
          },
          {
            "name": "Internships with employment ",
            "y": 345,
            "color": "#3142A3"
          },
          {
            "name": "Not Placed",
            "y": 3795,
            "color": "#ff0000"
          },
        ]
      }
    ],

    "seriespie": [
      {
        innerSize: '50%',
        size: '100%',
        "data": [
          {
            "name": "Oman",
            "y":  16,
            "color": "#75D284"
          },
          {
            "name": "Gulf",
            "y": 16,
            "color": "#3142A3"
          },
          {
            "name": "Rest of the World",
            "y": 7,
            "color": "#EAB600"
          },
        ]
      }
    ],
    
    "enrolmentsOverall": [
      {
        "name": "Achieved",
        "data": [4450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [6400, 6600, 6800, 6200,6500]
      }
    ],

    "enrolmentsMinistry": [
      {
        "name": "Achieved",
        "data": [1750, 1936, 2529, 2248,3308]
      },
      {
        "name": "Targeted",
        "data": [2700, 2904, 2701, 3372,2702]
      }
    ],

    "enrolmentsDirect": [
      {
        "name": "Achieved",
        "data": [2700, 2904, 2701, 3372,2702]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],

    "employabilityMixyear2023pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  162,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 241,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 404,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 483,
            "color": "#75D284"
          },
          {
            "name": "Event",
            "y": 322,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 241,
            "color": "#008B35"
          },
        ]
      }
    ],
  
    "employabilityMixyear2022pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  15000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 15000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 15000,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 15000,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 15000,
            "color": "#008B35"
          },
        ]
      }
    ],

    "employabilityMixyear2021pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  10000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 10000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 10000,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 10000,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 10000,
            "color": "#008B35"
          },
        ]
      }
    ],


    "conversionyear2023": [
      {
        "name": "Lead",
        "data": [919, 419, 443, 3024,193]
      },
      {
        "name": "Conversion",
        "data": [484, 322, 403, 2700,162]
      }
    ],


    "conversionyear2022": [
      {
        "name": "Achieved",
        "data": [2280, 1040, 1100, 2800,480]
      },
      {
        "name": "Targeted",
        "data": [1200, 800, 1000, 2500,400]
      }
    ],

    "conversionyear2021": [
      {
        "name": "Achieved",
        "data": [2450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [2000, 5500, 5800, 6200,6500]
      }
    ],
    
    "programWiseEnrollment": {

      "category":[
        'Diploma in Computer Science (Data Analytics)',
        'Master of Science in Cyber Security',
        'Bachelor of Science (Hons) in Logistics Management',
        'B.Eng. (Hons) Computer Engineering with pathway in Cybersecurity',
        'B.Eng. (Hons) Mechanical Engineering',
        'B.Eng. (Hons) Civil Engineering',
        'MSc Fintech',
        'MSc Cyber Security',
        'MSc Electronic Engineering',
        'MSc Construction Project and Cost Management',
      ],

      "data":[
        
        {   
            "name": "Fulltime",
            data: [90, 110,85, 80,50, 50,25, 50,60,60],
            color:"#3142A3",
          
          },
           {
            "name": "Internships",
            data: [125, 175,185, 100,75, 75,50, 80,100,150],
            color:"#59BFEC",
          },
          
        ]
    },




  },

  "2021-22": {

    "enrollmentsData": [
      {
        "totalAlumni": "400",
        "corporatesonboard": "40",
        "Oman": "35",
        "gulfCounties": "40",
        "Restoftheworld": "55",
        "graducate": "30",
        "postgraduate": "50",
        "certification": "61",
        "gfp": "71",
       
      },
    ],

    "series": [
      {
        
        size: '100%',
        "data": [
          {
            "name": "Employed",
            "y":  805,
            "color": "#008B35"
          },
          {
            "name": "Internships",
            "y": 3105,
            "color": "#657FE6"
          },
          {
            "name": "Internships with employment ",
            "y": 345,
            "color": "#3142A3"
          },
          {
            "name": "Not Placed",
            "y": 3795,
            "color": "#ff0000"
          },
        ]
      }
    ],

    "seriespie": [
      {
        innerSize:'50%',
        size: '100%',
        "data": [
          {
            "name": "Oman",
            "y":  16,
            "color": "#59BFEC"
          },
          {
            "name": "Gulf",
            "y": 16,
            "color": "#3142A3"
          },
          {
            "name": "Rest of the World",
            "y": 7,
            "color": "#657FE6"
          },
        ]
      }
    ],
    
    "enrolmentsOverall": [
      {
        "name": "Achieved",
        "data": [4450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],

    "enrolmentsMinistry": [
      {
        "name": "Achieved",
        "data": [1750, 1936, 2529, 2248,3308]
      },
      {
        "name": "Targeted",
        "data": [2700, 2904, 2701, 3372,2702]
      }
    ],
    "enrolmentsDirect": [
      {
        "name": "Achieved",
        "data": [2700, 2904, 2701, 3372,2702]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],

    "employabilityMixyear2023pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  601,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 2200,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 1503,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 1803,
            "color": "#75D284"
          },
          {
            "name": "Event",
            "y": 1202,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 902,
            "color": "#008B35"
          },
        ]
      }
    ],
  
    "employabilityMixyear2022pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  15000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 15000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 15000,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 15000,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 15000,
            "color": "#008B35"
          },
        ]
      }
    ],

    "employabilityMixyear2021pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  10000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 10000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 10000,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 10000,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 10000,
            "color": "#008B35"
          },
        ]
      }
    ],


    "conversionyear2023": [
      {
        "name": "Lead",
        "data": [919, 419, 443, 3024,193]
      },
      {
        "name": "Conversion",
        "data": [484, 322, 403, 2700,162]
      }
    ],


    "conversionyear2022": [
      {
        "name": "Achieved",
        "data": [3450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [3000, 5500, 5800, 6200,6500]
      }
    ],

    "conversionyear2021": [
      {
        "name": "Achieved",
        "data": [2450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [2000, 5500, 5800, 6200,6500]
      }
    ],
    
    "programWiseEnrollment": {

      "category":[
        'Diploma in Computer Science (Data Analytics)',
        'Master of Science in Cyber Security',
        'Bachelor of Science (Hons) in Logistics Management',
        'B.Eng. (Hons) Computer Engineering with pathway in Cybersecurity',
        'B.Eng. (Hons) Mechanical Engineering',
        'B.Eng. (Hons) Civil Engineering',
        'MSc Fintech',
        'MSc Cyber Security',
        'MSc Electronic Engineering',
        'MSc Construction Project and Cost Management',
      ],

      "data":[
        
        {   
            "name": "Fulltime",
            data: [90, 110,85, 80,50, 50,25, 50,60,60],
            color:"#3142A3",
          
          },
           {
            "name": "Internships",
            data: [125, 175,185, 100,75, 75,50, 80,100,150],
            color:"#59BFEC",
          },
          
        ]
    },



  }
}
export { response }
