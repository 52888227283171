import Overview from "../pages/Overview";
import Review from "../pages/Revenue";
import School from "../pages/School";
import StoreFront from "../dashboard/store-front";
import Revenue from "../pages/Revenue";
import Enrolments from "../pages/Enrolments";
import Programs from "../pages/Programs";
import Employability from "../pages/Employability";
import ProgramDetail from "../pages/ProgramDetail";
import EventsDashboard from "../pages/EventsDashboard";
import AdmissionDashboard from "../pages/Admission"

import MecUsers from "../components/MecUsers";
import StudentCourse from "../pages/StudentCourse";
import SemesterWiseClass from "../pages/SemesterWiseClass";
import Top2SponsorsChart from "../pages/Top2SponsorsChart";
import StudentsSummaryChart from "../pages/StudentSummary";
import { Crm } from "../pages/CRM/Crm";
import FinancialDashboard from "../pages/CRM/Finance/Finance";
import Community from "../pages/Community/Community";

const routes = [
    { component: Overview, path: "/overview" },
    { component: Review, path: "/revenue-detail" },
    { component: Revenue, path: "/revenue" },
    { component: StoreFront, path: "/store-front" },
    { component: School, path: "/school" },
    { component: Employability, path: "/employability" },
    { component: Enrolments, path: "/enrollments" },
    { component: Programs, path: "/programmes" },
    { component: ProgramDetail, path: "/programmes/detail/:id" },
    { component: EventsDashboard, path: "/event" },
    { component: MecUsers, path: "/create-user" },
    { component: AdmissionDashboard, path: "/admission-dashboard" },
    { component: StudentCourse, path: "/student-course" },
    { component: SemesterWiseClass, path: "/semester-class" },
    { component: Top2SponsorsChart, path: "/top-two-sponsors" },
    { component: StudentCourse, path: "/student-course" },
    { component: Crm, path: "/crm" },
    { component: FinancialDashboard, path: "/finance" },
    { component: Community, path: "/community" },

];

export default routes;