import React from 'react';
import { useAppSelector } from '../redux/store';
import './common.css';

const Header = () => {
  const pageTitle = useAppSelector((state) => state.pageTitle.title);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <header>
      <div className='headerContainer'>
        {/* <div className="backLink" style={{ backgroundColor: 'black', height: '50px' }}>
          <img 
            src="https://mecfuture.mec.edu.om/images/logo/130-bg-1709531140.png" 
            alt="Logo" 
            style={{ height: '100%', objectFit: 'fill' , padding: '10px'  }} 
          />
        </div> */}
        <div className='backLink'>
          <h1 className='text-2xl font-bold'>{pageTitle}</h1>
        </div>

        <div className='headerActions'>
          <div className="logout">
            <span
              className="material-symbols-outlined"
              onClick={handleLogout}
              style={{ cursor: 'pointer' }}
            >
              logout
            </span>
          </div>
          <div className='notification'><span className="material-symbols-outlined">notifications</span></div>
          <div className="profileBlock">
            <div className="accountDD" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i><img alt='logo' src={`${JSON.parse(localStorage.getItem('user'))?.profile_image}`} /></i>
              <div>
                <p>Admin</p>
                <h6>MEC Admin</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
