import React from 'react'
import ProgramSponsor from '../../components/CRM/ProgramSponsor'
import SelectSponsor from '../../components/CRM/SelectSponsor'
import ProgramsWithSponsors from '../../components/CRM/ProgramsWithSponsors'

export const Crm = () => {
  return (
    <div>
      <h1 className='font-bold text-2xl mb-4'>CRM Dashboard</h1>
      <ProgramSponsor />
      <ProgramsWithSponsors />
      {/* <SelectSponsor /> */}
    </div>
  )
}
