import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'

const DepartmentEvent = (props) => {
    const {data, name, yTitle} = props
    return (
        <div className='chartWrapper'>
            <HighchartsReact
                highcharts={Highcharts}

                options={{
                    chart: {
                        type: 'column',

                    },
                    credits: { enabled: false },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        categories: data?.map((data) => data?.name)

                    },
                    yAxis: {
                        title: {
                            text: yTitle,
                        },
                    },

                    series: [{
                        name: name,
                        colorByPoint: true,
                        data: data?.map(item => ({
                            name: item.name,
                            y: parseInt(item.event_count, 10) || 0, // Ensure the value is a number
                        })),
                    }],
                }}
            />
        </div>
    )
}

export default DepartmentEvent