// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './slice/apiSlice';
import { authSlice } from './slice/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import pageTitleReducer from './slice/pageTitleSlice';

// Create the store with the root reducer and any middleware
const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [authSlice.reducerPath]: authSlice.reducer,
    pageTitle: pageTitleReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware).concat(authSlice.middleware),
});

// Custom hooks for dispatch and selector
export const useAppDispatch = () => useDispatch();
export const useAppSelector = (selector) => useSelector(selector);

export default store;
