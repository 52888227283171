const response = {

  "1": {

    "programData" : [
      {
      "name": "AIML",
      "startdate": "01 Jan 2024",
      "completiondate": " 21 Jan 2024",
      }
    ],

    "enrollmentsData": [
      {
        "revenue": "73,560",
        "revenueprogress":"95",
        "revenuetarget":"77,800",
        "enrollments":'120',
        "enrollmentsprogress":"95",
        "enrollmentstarget":"150",
        "cpa":'27',
        "cpaprogress":"95",
        "cpatarget":"30",
        "conversionrate":'0.8',
        "conversionrateprogress":"95",
        "conversionratetarget":"1",
        "budget":"1510 OMR",
        "spends":"1426 OMR",
        "exhausted":"69%",
       
      },
     
    ],

    
    "revenueSourceMix2023": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  25000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 25000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 5000,
            "color": "#3142A3"
          },
        ]
      }
    ],


    "revenueSourceMix2022": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  21000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 28000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 10000,
            "color": "#3142A3"
          },
          
        ]
      }
    ],
  


    "revenueSourceMix2021": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  35000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 55000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 1000,
            "color": "#3142A3"
          },
          
        ]
      }
    ],

   


    "conversionData2023": [
      {
          "name": "Leads",
          "data": [919, 419, 443, 3024,193],
          "color":"#3142A3"
        },
        {
          "name": "Conversion",
          "data": [484, 322, 403, 2700,162],
          "color":"#59BFEC"
        },
        {
          "name": "Targets",
          "data": [484, 322, 403, 2700,162],
          "color":"#657FE6"
        },
        
    ],
  
    "conversionData2022": [
      {
        "name": "Leads",
        "data": [919, 419, 443, 3024,193],
        "color":"#3142A3"
      },
      {
        "name": "Conversion",
        "data": [484, 322, 403, 2700,162],
        "color":"#59BFEC"
      },
      {
        "name": "Targets",
        "data": [484, 322, 403, 2700,162],
        "color":"#657FE6"
      },
    ],

    "conversionData2021": [
      {
        "name": "Leads",
        "data": [919, 419, 443, 3024,193],
        "color":"#3142A3"
      },
      {
        "name": "Conversion",
        "data": [484, 322, 403, 2700,162],
        "color":"#59BFEC"
      },
      {
        "name": "Targets",
        "data": [484, 322, 403, 2700,162],
        "color":"#657FE6"
      },
    ],


    "revenue2023": [
      {
        "data": [
          {
            "name": "Actual Revenue",
            "y":  420,
            "color": "#008B35"
          },
          {
            "name": "Planned Revenue",
            "y": 900,
            "color": "#00BC4B"
          },
        ]
      }
    ],


    "revenue2022": [
      {
        "data": [
          {
            "name": "Actual Revenue",
            "y":  220,
            "color": "#008B35"
          },
          {
            "name": "Planned Revenue",
            "y": 700,
            "color": "#00BC4B"
          },
        ]
      }
    ],

    "revenue2021": [
      {
        "data": [
          {
            "name": "Actual Revenue",
            "y":  300,
            "color": "#008B35"
          },
          {
            "name": "Planned Revenue",
            "y": 1000,
            "color": "#00BC4B"
          },
        ]
      }
    ],
    
    "programWiseEnrollment": {

      "category":[
        'Google Ads',
        'Instagram Ads',
        'Youtube ADs',
        'Snapchat',
        'Tiktok',
        'News Papers',
        'Referrals',
      ],

      "data":[
        
        {   
            "name": "Spends",
            data: [23, 32,23, 32,23, 32,23],
            color:"#EAB600",
          
          },
           {
            "name": "Leads",
            data: [23, 32,23, 32,23, 32,23],
            color:"#59BFEC",
          },
           {
            "name": "Conversion",
            data: [23, 32,23, 32,23, 32,23],
            color:"#00BC4B",
          },
          
        ]
    },



  },


  "2": {

    "programData" : [
      {
      "name": "B.Eng. (Hons) Computer Engineering with a pathway in Networking",
      "startdate": "01 Jan 2024",
      "completiondate": " 21 Jan 2024",
      }
    ],

    "enrollmentsData": [
      {
        "revenue": "73,560",
        "revenueprogress":"95",
        "revenuetarget":"77,800",
        "enrollments":'120',
        "enrollmentsprogress":"95",
        "enrollmentstarget":"150",
        "cpa":'27',
        "cpaprogress":"95",
        "cpatarget":"30",
        "conversionrate":'0.8',
        "conversionrateprogress":"95",
        "conversionratetarget":"1",
        "budget":"1510 OMR",
        "spends":"1426 OMR",
        "exhausted":"69%",
       
      },
     
    ],

    
    "revenueSourceMix2023": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  25000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 25000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 5000,
            "color": "#3142A3"
          },
        ]
      }
    ],


    "revenueSourceMix2022": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  21000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 28000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 10000,
            "color": "#3142A3"
          },
          
        ]
      }
    ],
  


    "revenueSourceMix2021": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  35000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 55000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 1000,
            "color": "#3142A3"
          },
          
        ]
      }
    ],

   


    "conversionData2023": [
      {
          "name": "Leads",
          "data": [919, 419, 443, 3024,193],
          "color":"#3142A3"
        },
        {
          "name": "Conversion",
          "data": [484, 322, 403, 2700,162],
          "color":"#59BFEC"
        },
        {
          "name": "Targets",
          "data": [484, 322, 403, 2700,162],
          "color":"#657FE6"
        },
        
    ],
  
    "conversionData2022": [
      {
        "name": "Leads",
        "data": [919, 419, 443, 3024,193],
        "color":"#3142A3"
      },
      {
        "name": "Conversion",
        "data": [484, 322, 403, 2700,162],
        "color":"#59BFEC"
      },
      {
        "name": "Targets",
        "data": [484, 322, 403, 2700,162],
        "color":"#657FE6"
      },
    ],

    "conversionData2021": [
      {
        "name": "Leads",
        "data": [919, 419, 443, 3024,193],
        "color":"#3142A3"
      },
      {
        "name": "Conversion",
        "data": [484, 322, 403, 2700,162],
        "color":"#59BFEC"
      },
      {
        "name": "Targets",
        "data": [484, 322, 403, 2700,162],
        "color":"#657FE6"
      },
    ],


    "revenue2023": [
      {
        "data": [
          {
            "name": "Actual Revenue",
            "y":  420,
            "color": "#008B35"
          },
          {
            "name": "Planned Revenue",
            "y": 900,
            "color": "#00BC4B"
          },
        ]
      }
    ],


    "revenue2022": [
      {
        "data": [
          {
            "name": "Actual Revenue",
            "y":  220,
            "color": "#008B35"
          },
          {
            "name": "Planned Revenue",
            "y": 700,
            "color": "#00BC4B"
          },
        ]
      }
    ],

    "revenue2021": [
      {
        "data": [
          {
            "name": "Actual Revenue",
            "y":  300,
            "color": "#008B35"
          },
          {
            "name": "Planned Revenue",
            "y": 1000,
            "color": "#00BC4B"
          },
        ]
      }
    ],
    
    "programWiseEnrollment": {

      "category":[
        'Google Ads',
        'Instagram Ads',
        'Youtube ADs',
        'Snapchat',
        'Tiktok',
        'News Papers',
        'Referrals',
      ],

      "data":[
        
        {   
            "name": "Spends",
            data: [23, 32,23, 32,23, 32,23],
            color:"#EAB600",
          
          },
           {
            "name": "Leads",
            data: [23, 32,23, 32,23, 32,23],
            color:"#59BFEC",
          },
           {
            "name": "Conversion",
            data: [23, 32,23, 32,23, 32,23],
            color:"#00BC4B",
          },
          
        ]
    },



  },

 }
export { response }
