import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../common/common.css';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { response } from '../mapdata/year1';
import { useEnrollmentsSourceEnrollmentQuery, useCrmQuery } from '../redux/slice/apiSlice';
import { formatNumberWithCommas } from '../utils/utils';


const Overview = () => {
    const [enrolmentsOverall, setEnrolmentsOverall] = useState(true);
    const [enrolmentsMinistry, setEnrolmentsMinistry] = useState(false);
    const [enrolmentsDirect, setEnrolmentsDirect] = useState(false);
    const [yearChange, setYearChange] = useState('2023-24');
    const [year2324, setYear2324] = useState(true);
    const [year2223, setYear2223] = useState(false);
    const [year2122, setYear2122] = useState(false);
    const { data: crmResponse, error: crmError } = useCrmQuery()
    const { data: enrollmentsSourceData, isLoading: enrollmentsSourceDataLoading, error: enrollmentsSourceEnrollmentError } = useEnrollmentsSourceEnrollmentQuery();


    useEffect(() => {
        // Cleanup Highcharts options on unmount
        return () => {
            Highcharts.setOptions({
                lang: {
                    decimalPoint: '.',
                    thousandsSep: ','
                }
            });
        };
    }, []);

    const EnrolmentsButtonClick = (tab) => {
        if (tab === "overall") {
            setEnrolmentsOverall(true);
            setEnrolmentsMinistry(false);
            setEnrolmentsDirect(false);
        } else if (tab === "ministry") {
            setEnrolmentsMinistry(true);
            setEnrolmentsOverall(false);
            setEnrolmentsDirect(false);
        } else {
            setEnrolmentsDirect(true);
            setEnrolmentsOverall(false);
            setEnrolmentsMinistry(false);
        }
    };

    const YearChange = (e) => {
        const selectedYear = e.target.value;
        setYearChange(selectedYear);

        if (selectedYear === "2023-24") {
            setYear2324(true);
            setYear2223(false);
            setYear2122(false);
        } else if (selectedYear === "2022-23") {
            setYear2324(false);
            setYear2223(true);
            setYear2122(false);
        } else if (selectedYear === "2021-22") {
            setYear2324(false);
            setYear2223(false);
            setYear2122(true);
        }
    };

    const yearData = response[yearChange];



    return (
        <div className='overviewWrapper'>
            <section>
                <div className='infoWrapper'>
                    <div className='pageTitle'>
                        <h1>Overview</h1>
                        <p>Institutional and operational KPIs</p>
                    </div>
                    <div className='pageActions'>
                        <label>Year</label>
                        <select onChange={(e) => this.YearChange(e)} >
                            <option value={"2023-24"}>2023-24 (Base Year)</option>
                            <option value={"2022-23"}>2022-23</option>
                            <option value={"2021-22"}>2021-22</option>
                        </select>
                    </div>
                </div>
            </section>
            <section className='cardInfoWrapper'>
                <ol>
                    <li>
                        <NavLink to="/revenue">
                            <div className='topSection'>
                                <i className="material-symbols-outlined">payments</i>
                                <div>
                                    <p>Revenue</p>
                                    <h2>9.7m (gap)</h2>
                                </div>
                            </div>
                            <div className='progressWrapper'>
                                <div className='progressInfo'>
                                    {/* <div><span>97%</span>Achieved</div>
                                    <div>Target : 10m</div> */}
                                </div>
                                {/* <div className="progress">
                                    <div className="progress-bar" style={{ width: `97%` }}></div>
                                </div> */}
                            </div>
                            <div className='actionInfo'>
                                
                                <div>
                                    <p>Sources</p>
                                    <span>2.82m</span>
                                </div>
                                <div>
                                    <p>GL Accounts</p>
                                    <span>8.60m</span>
                                </div>
                                <div>
                                    <p>Debit</p>
                                    <span>2.82m</span>
                                </div>
                                <div>
                                    <p>Credit</p>
                                    <span>0.85m</span>
                                </div>
                               


                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/enrollments">
                            <div className='topSection'>
                                <i className="material-symbols-outlined">school</i>
                                <div>
                                    <p>Leads</p>
                                    {/* <h2>{formatNumberWithCommas(crmResponse?.data?.applicant)}</h2> */}
                                    <h2>2023</h2>
                                </div>
                            </div>
                            <div className='progressWrapper'>
                                <div className='progressInfo'>
                                    {/* <div><span>{enrollmentsSourceData?.data?.percentage_increased}%</span>Achieved</div>
                                    <div>Target : {formatNumberWithCommas(enrollmentsSourceData?.data?.total_target ?? 5000)}</div> */}
                                </div>
                                {/* <div className="progress"> */}
                                {/* <div className="progress-bar" style={{ width: `${enrollmentsSourceData?.data?.percentage_increased}%` }}></div> */}
                                {/* </div> */}
                            </div>
                            <div className='actionInfo'>
                                <div>
                                    <p>Direct</p>
                                    <span>1200</span>
                                </div>
                                <div>
                                    {/* </div> */}
                                    <p>MoHERI</p>
                                    <span>680</span>
                                </div>
                                {/* </div>) */}
                                <div>
                                    <p>Others</p>
                                    <span>143</span>
                                </div>


                                {/* {
                                    enrollmentsSourceData?.data?.pie_chart_data?.map((data) =>
                                        <div>
                                            <p>{data?.enrollment_source}</p>
                                            <span>{formatNumberWithCommas(data?.total_enrollment)}</span>
                                        </div>)
                                } */}

                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/programmes">
                            <div className='topSection'>
                                <i className="material-symbols-outlined">book_2</i>
                                <div>
                                    <p>Programmes</p>
                                    <h2>{crmResponse?.data?.program}</h2>
                                </div>
                            </div>
                            <div className='progressWrapper'>
                                <div className='progressInfo'>
                                    in 15 Departments
                                </div>
                            </div>
                            <div className='actionInfo'>

                                {/* {enrollmentsSourceData?.data?.course_data?.map((source, index) => (
                                    <div className='flex' key={index}>
                                        <p>{source.program_name}</p>
                                        <span>{formatNumberWithCommas(source.total_enrollment)}</span>

                                    </div>
                                ))} */}
                                <div>
                                    <p>Bachelor</p>
                                    <span>20</span>
                                </div>
                                <div>
                                    {/* </div> */}
                                    <p>Diploma</p>
                                    <span>16</span>
                                </div>
                                {/* </div>) */}
                                <div>
                                    <p>GFP-Fnd</p>
                                    <span>3</span>
                                </div>
                                <div>
                                    <p>Masters</p>
                                    <span>7</span>
                                </div>


                                {/* <div>
                                    <p>Diploma</p>
                                    <span>{enrollmentsSourceData.course_data.total_enrollment}</span>
                                </div>
                                <div>
                                    <p>Graduate</p>
                                    <span>48</span>
                                </div>
                                <div>
                                    <p>GFP</p>
                                    <span>3</span>
                                </div>
                                <div>
                                    <p>Master</p>
                                    <span>24</span>
                                </div>
                                <div>
                                    <p>Other Text</p>
                                    <span>13</span>
                                </div> */}
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/employability">
                            <div className='topSection'>
                                <i className="material-symbols-outlined">work</i>
                                <div>
                                    <p>Employability</p>
                                    <h2>76</h2>
                                </div>
                            </div>
                            <div className='progressWrapper'>
                                <div className='progressInfo'>
                                    {/* <div><span>85%</span>Achieved</div>
                                    <div>Target : 1000</div> */}
                                </div>
                                {/* <div className="progress">
                                    <div className="progress-bar" style={{ width: `85%` }}></div>
                                </div> */}
                            </div>
                            <div className='actionInfo'>
                                <div>
                                    <p>Jobs Post</p>
                                    <span>39</span>
                                </div>
                                <div>
                                    <p>Published </p>
                                    <span>14</span>
                                </div>
                                <div>
                                    <p>Pipeline</p>
                                    <span>23</span>
                                </div>
                                <div>
                                    <p>Internships</p>
                                    <span>0</span>
                                </div>
                                {/* <div>
                                    <p>Corporate Partnership</p>
                                    <span>170</span>
                                </div> */}
                            </div>
                        </NavLink>
                    </li>
                </ol>

            </section>

            <section className='chartWrapper'>
                <div className='chartLeft'>
                    <div className='chartheading'>
                        <h3>Revenue Source Mix (source type and debits)</h3>
                    </div>
                    {console.log("23-24", year2324)}
                    {console.log("22-23", year2223)}
                    {console.log("21-22", year2122)}

                    {year2324 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'pie',

                                },

                                accessibility: {
                                    point: {
                                        valueSuffix: '%'
                                    }
                                },
                                tooltip: {
                                    enabled: false,
                                    pointFormat: '{point.x:,.0f}{point.y}',
                                },
                                title: {
                                    text: 'Rental & Misc Income : ' + response[yearChange].series[0].total
                                },
                                legend: {
                                    enabled: true,
                                    floating: false,
                                    borderWidth: 0,
                                    align: 'right',
                                    layout: 'vertical',
                                    verticalAlign: 'middle',

                                },
                                plotOptions: {
                                    series: {
                                        allowPointSelect: true,
                                        showInLegend: true,
                                        dataLabels: {
                                            enabled: true,
                                            format: '{point.name} - {point.y}' + "m",

                                        },

                                    }
                                },
                                series: yearData.series,



                            }}
                        />
                    }

                    {year2223 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'pie',

                                },

                                accessibility: {
                                    point: {
                                        valueSuffix: '%'
                                    }
                                },
                                tooltip: {
                                    enabled: false,
                                    pointFormat: '<b>{point.y}</b>'
                                },
                                title: {
                                    text: 'Rental & Misc Income :' + response[yearChange].series[0].total
                                },
                                legend: {
                                    enabled: true,
                                    floating: false,
                                    borderWidth: 0,
                                    align: 'right',
                                    layout: 'vertical',
                                    verticalAlign: 'middle',
                                    // labelFormatter: function() {
                                    //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                    // }
                                },
                                plotOptions: {
                                    series: {
                                        allowPointSelect: true,
                                        showInLegend: true,
                                        dataLabels: {
                                            enabled: true,
                                            format: '{point.name}: {point.y:,.0f}'

                                        },

                                    }
                                },
                                series: yearData.series,



                            }}
                        />
                    }

                    {year2122 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'pie',

                                },

                                accessibility: {
                                    point: {
                                        valueSuffix: '%'
                                    }
                                },
                                tooltip: {
                                    enabled: false,
                                    pointFormat: '<b>{point.y}</b>'
                                },
                                title: {
                                    text: 'Rental & Misc Income :' + response[yearChange].series[0].total
                                },
                                legend: {
                                    enabled: true,
                                    floating: false,
                                    borderWidth: 0,
                                    align: 'right',
                                    layout: 'vertical',
                                    verticalAlign: 'middle',
                                    // labelFormatter: function() {
                                    //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                    // }
                                },
                                plotOptions: {
                                    series: {
                                        allowPointSelect: true,
                                        showInLegend: true,
                                        dataLabels: {
                                            enabled: true,
                                            format: '{point.name}: {point.y:,.0f}'

                                        },

                                    }
                                },
                                series: yearData.series,
                            }}
                        />
                    }
                </div>
                <div className='chartRight'>
                    <div className='chartheading'>
                        <h3>Enrolments</h3>
                        <div>
                            <span className={enrolmentsOverall === true ? 'active' : ''} onClick={(e) => this.EnrolmentsButtonClick('overall')} >Overall</span>
                            <span className={enrolmentsMinistry === true ? 'active' : ''} onClick={(e) => this.EnrolmentsButtonClick('ministry')}>Ministry</span>
                            <span className={enrolmentsDirect === true ? 'active' : ''} onClick={(e) => this.EnrolmentsButtonClick('direct')}>Direct</span>
                        </div>
                    </div>
                    {year2324 && enrolmentsOverall &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ]
                                },

                                series: response[yearChange].enrolmentsOverall
                            }}
                        />
                    }

                    {year2223 && enrolmentsOverall &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ]
                                },

                                series: response[yearChange].enrolmentsOverall
                            }}
                        />
                    }

                    {year2122 && enrolmentsOverall &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ]
                                },

                                series: response[yearChange].enrolmentsOverall
                            }}
                        />
                    }

                    {year2324 && enrolmentsMinistry &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ]
                                },
                                series: response[yearChange].enrolmentsMinistry
                            }}
                        />
                    }

                    {year2223 && enrolmentsMinistry &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ]
                                },
                                series: response[yearChange].enrolmentsMinistry
                            }}
                        />
                    }

                    {year2122 && enrolmentsMinistry &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ]
                                },
                                series: response[yearChange].enrolmentsMinistry
                            }}
                        />
                    }

                    {year2324 && enrolmentsDirect &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ]
                                },
                                series: response[yearChange].enrolmentsDirect
                            }}
                        />
                    }
                    {year2223 && enrolmentsDirect &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ]
                                },
                                series: response[yearChange].enrolmentsDirect
                            }}
                        />
                    }
                    {year2122 && enrolmentsDirect &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ]
                                },
                                series: response[yearChange].enrolmentsDirect
                            }}
                        />
                    }
                </div>
            </section>


            <section className='chartWrapper'>
                <div className='chartLeft'>
                    <div className='chartheading'>
                        <h3>Employment rate</h3>
                    </div>
                    {year2324 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'column'
                                },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2020-21', '2021-22', '2022-23', '2023-24'
                                    ]
                                },
                                series: response[yearChange].employment
                            }}
                        />
                    }

                    {year2223 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'column'
                                },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2020-21', '2021-22', '2022-23', '2023-24'
                                    ]
                                },
                                series: response[yearChange].employment
                            }}
                        />
                    }

                    {year2122 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'column'
                                },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2020-21', '2021-22', '2022-23', '2023-24'
                                    ]
                                },
                                series: response[yearChange].employment
                            }}
                        />
                    }
                </div>
                <div className='chartRight'>
                    <div className='chartheading'>
                        <h3>Overall student satisfaction</h3>
                    </div>

                    <div className='chartAlignmentWrapper'>
                        {response[yearChange].studentsatisfaction.map((item, index) =>
                            <div className='progressWrapper' key={index}>
                                <div className='progressInfo'>
                                    <div>{item.name}</div>
                                    <div>{item.progress}%</div>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar" style={{ width: `${item.progress}%` }}></div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </section>


        </div>
    );
};

export default Overview;
