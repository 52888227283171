import React from 'react'

const CommunityOverview= (props) => {
    const { community } = props;

    return (
        <div className="grid grid-cols-6 gap-4 mb-6">
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{community?.total_post ?? 0}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Post</p>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{community?.total_reel ?? 0}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Reels</p>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{community?.role_post?.filter((data) => data?.role == 'Learner')[0]?.post_count ?? 0}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Students Participation</p>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{community?.role_post?.filter((data) => data?.role == 'Alumni')[0]?.post_count ?? 0}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Alumni Participation</p>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{community?.role_post?.filter((data) => data?.role == 'Presenter')[0]?.post_count ?? 0}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Faculties</p>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{community?.total_departmet_post ?? 0}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Departments Participated</p>
            </div>


        </div>
    )
}

export default CommunityOverview