import React, { Component } from 'react';
import '../common/common.css';

class StoreFront extends Component {
  constructor(props) {
    super(props);
    this.state = {
        
    }
    
  }

  componentDidMount() {
    
  }

  

  

  componentWillUnmount() {
   
  }

 


	render() {

    		return (
			      <div className='storeFrontWrapper'>
                StoreFront here
            </div>
        )
	}
}



export default StoreFront;