import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import React from "react";

import { response } from "../mapdata/programmesData";
import { formatNumberWithCommas } from '../utils/utils';

const tableHead = {
    // icon: "Icon",
    course: "Course/ Programme Name",
    targetRevenue: "Target Revenue",
    actualRevenue: "Actual Revenue",
    targetEnrolments: "Target Enrolments",
    actualEnrolments: "Actual Enrolments",
    batchStatus: "Batch Status",
};

const Table = (props) => {
    const { rows,total,  onPageChange, onSearchChange, currentPage } = props;

    const countPerPage = 10;


    const updatePage = (p) => {};

    const tableRows = (rowData) => {
        const { key, index } = rowData;
        const tableCell = Object.keys(tableHead);
        const columnData = tableCell.map((keyD, i) => {
            return (
                <td key={i}>
                    {i == 0 ? (
                        <img className="thumb" src={key[keyD]} />
                    ) : (
                        <div
                            onClick={() => {
                                if (i == 1) {
                                    console.log(key);
                                    window.location.href =
                                        "programmes/detail/" + [key["id"]] + "";
                                }
                            }}
                            className={
                                i == 1 ? "link" : key[keyD].split(" ").join("")
                            }
                        >
                            {key[keyD]}
                        </div>
                    )}
                </td>
            );
        });

        return <tr key={index}>{columnData}</tr>;
    };

    const headRow = () => {
        return Object.values(tableHead).map((title, index) => (
            <td key={index}>{title}</td>
        ));
    };



    return (
        <>
            <div class="search">
                <input
                    placeholder="Search"
                    onChange={onSearchChange}
                />
            </div>
            <table>
                <thead>
                    <tr>{headRow()}</tr>
                </thead>
                <tbody className="trhover">
                    {rows.map((data) => (
                        <tr>
                            {/* <td>
                                <img
                                    className="thumb"
                                    src={
                                        data?.image ??
                                        "https://mecfuture.mec.edu.om/images/programs/program_161023115456.jpeg"
                                    }
                                    alt=""
                                />
                            </td> */}
                            <td>{data.program_name}</td>
                            <td>{formatNumberWithCommas(data?.targe_revenue)}</td>
                            <td>{formatNumberWithCommas(data?.actual_revenue)}</td>
                            <td>{formatNumberWithCommas(data.enrollment_projected)}</td>
                            <td>{formatNumberWithCommas(data?.enrollment_accepted)}</td>
                            <td>{data.is_enrollment_active}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                pageSize={countPerPage}
                onChange={onPageChange}
                current={currentPage}
                total={total}
            />
        </>
    );
};

export default Table;