import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGetStudentSummaryQuery } from '../redux/slice/apiSlice';

const StudentsSummaryChart = () => {
  const { data: response, error: error } = useGetStudentSummaryQuery()
  const studentSummary = response?.data;
 
 
  return (
    <div className='bg-white p-2 m-2 rounded-md'>
      <h3 className="text-xl font-bold mb-2">Student Summary</h3>

      <table className="table-auto border border-gray-300 w-full fixed_header max-h-[300px]">
        <thead>
          <tr className="bg-blue-500 text-black">
            <th className="border border-gray-300 px-4 py-2">University Name</th>
            <th className="border border-gray-300 px-4 py-2">No. of Students</th>
          </tr>
        </thead>

        <tbody className='max-h-[260px]'>
          {studentSummary?.university_users.map((item) => (
            <tr key={item.university}>
              <td className="border border-gray-300 px-4 py-2">{item.university}</td>
              <td className="border border-gray-300 px-4 py-2">{item.user_count}</td>
            </tr>
          ))}
          <tr className='bg-[#67d9eb]'>
            <td className="border border-gray-300 px-4 py-2 font-bold">Grade Total</td>
            <td className="border border-gray-300 px-4 py-2 font-bold">{JSON.stringify(studentSummary?.university_users?.reduce((sum, value) => sum + value?.user_count, 0))}</td>
          </tr>
        </tbody>
      </table>



     <div className="revenueWrapper">
     <section className='chartWrapper'>
        <div className='chartLeft'>
          <div className='chartheading'>
            <div className='block'>
              <h3>  Semester wise Admission</h3>
              {/* <a href='#'>View all <i className='fa fa-chevron-right'></i></a> */}
            </div>
          </div>
          <div className='chatWrapper'>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: 'bar'
                },
                title: {
                  text: ''
                },
                subtitle: {
                  text: ''
                },
                xAxis: {
                  categories: studentSummary?.student_adminssion.map((data) => data?.AcademicYear),
                  title: {
                    text: 'Student Joining Date and Still in Continuation '
                  }
                },
                yAxis: {
                  min: 0,
                  title: {
                    text: 'Number of Students'
                  }
                },
                tooltip: {
                  pointFormat: '{series.name}: <b>{point.y}</b>'
                },
                plotOptions: {
                  column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                  }
                },
                series: Object.keys(studentSummary?.student_adminssion[0]?.sponsor || {}).map((sponsor) => {
                  return {
                    name: sponsor, // Sponsor name (e.g. MEC, MOHE, Direct, Others)
                    data: studentSummary?.student_adminssion.map((year) => {
                      const counts = year?.sponsor[sponsor]; // Extract sponsor data for each year
                      const total = Object.values(counts).reduce((sum, count) => sum + count, 0); // Sum the counts for all degrees
                      return total;
                    })
                  };
                })
              }}
            />

          </div>
        </div>

        <div className='chartRight'>
          <div className='chartheading'>
            <div className='block'>
              <h3>Student Joining Date and Still in Continuation </h3>

            </div>
          </div>
          <div className='chatWrapper'>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: 'column'
                },
                title: {
                  text: ''
                },
                xAxis: {
                  categories: studentSummary?.semester_university.map((data) =>
                    Object.keys(data?.user_count)[0] // Get university name
                  )
                },
                yAxis: {
                  min: 0,
                  title: {
                    text: 'Number of Users'
                  }
                },
                legend: {
                  reversed: true
                },
                plotOptions: {
                  series: {
                   
                    dataLabels: {
                      enabled: true
                    }
                  }
                },
                series: studentSummary?.semester_university.map((semesterData) => ({
                  name: semesterData.semester,
                  data: Object.values(semesterData.user_count) // Get user counts for the universities
                }))
              }}
            />


          </div>
        </div>


      </section>
     </div>

    </div>
  );
};

export default StudentsSummaryChart;