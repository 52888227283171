import React, { useState } from 'react'
import { useGetUserQuery } from '../redux/slice/apiSlice'
import './MecUsers.css' // Ensure this CSS file is created as shown earlier

import AddUserModal from './AddUserModel'
const MecUsers = () => {
  const { data: userList, isLoading: userListLoading, error: userListError } = useGetUserQuery();
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);


  const handleSearchChange = (e) => setSearchTerm(e.target.value.toLowerCase());
  const filteredUsers = userList?.data?.user_list?.filter(user =>
    user.user_name.toLowerCase().includes(searchTerm) ||
    user.email.toLowerCase().includes(searchTerm) ||
    user.role.toLowerCase().includes(searchTerm)
  );

  const openModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  if (userListLoading) return <p>Loading...</p>;
  if (userListError) return <p>Error loading users.</p>;

  return (
    <div className="table-container">
      <h1 className='text-xl font-bold my-4'>Mec BD Users</h1>
      <div className="flex justify-between mb-4">
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="border border-gray-300 p-2 rounded"
        />
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
          onClick={openModal}
        >
          Add User
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th>User Name</th>
            <th>Email</th>
            <th>Mobile No</th>
            <th>Role</th>
            <th>Status</th>
            <th>Department Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers?.map((user, index) => (
            <tr key={index}>
              <td>{user.user_name}</td>
              <td>{user.email}</td>
              <td>{user.mobile_no || ''}</td>
              <td>{user.role == 'Presenter' ? 'Staff' : user.role }</td>
              <td>{user.status}</td>
              <td>{user.department_name || ''}</td>
              <td>
                <span className="material-symbols-outlined cursor-pointer" onClick={() => openModal(user)}>
                  edit
                </span>
              </td>

            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && <AddUserModal
        user={selectedUser}
        closeModal={closeModal}
      />}
    </div>
  )
}

export default MecUsers;
