import React, { useState, useEffect } from 'react';
import '../common/common.css';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Table from '../dashboard/table';
import { response } from '../mapdata/programmesData';
import { useGetProgramListQuery, useEnrollmentsSourceEnrollmentQuery, useGetEventDashboardQuery, useGetEventEnrollmentQuery } from '../redux/slice/apiSlice';
import DepartmentEvent from '../dashboard/DepartmentEvent';
import { formatNumberWithCommas } from '../utils/utils';

const EventsDashboard = () => {
    const [yearChange, setYearChange] = useState('2023-24');
    const { data: programListData, error: programListDataError, refetch: refetchProgramListData } = useGetProgramListQuery();
    const { data: enrollmentsSourceData, isLoading: enrollmentsSourceDataLoading, error: enrollmentsSourceEnrollmentError } = useEnrollmentsSourceEnrollmentQuery();
    const { data: getEventsDashboard, isLoading: getEventsDashboardLoading, error: getEventsDashboardError } = useGetEventDashboardQuery();
    const { data: getEventsEnrollment, isLoading: getEventsEnrollmentLoading, error: getEventsEnrollmentError } = useGetEventEnrollmentQuery();
    return (
        <div className='programmesWrapper'>
            <section>
                <div className='infoWrapper'>
                    <div className='pageTitle'>
                        <h1>Events</h1>
                    </div>
                </div>
            </section>



            <section className='cardInfoWrapper dashboard_event'>
                <ol>
                    {yearChange && (
                        <>
                            <li>
                                <div>
                                    <div className='cardLeft'>
                                        <p>Total Active Events</p>
                                        <h2>{formatNumberWithCommas(getEventsDashboard?.data.total_active_events)}</h2>

                                    </div>
                                    <div className='cardLeft'>
                                        <p>Total User Enrolled</p>
                                        <h2>{formatNumberWithCommas(getEventsDashboard?.data.total_user_enrolled)}</h2>

                                    </div>
                                    <div className='cardLeft'>
                                        <p>User Event Attempted</p>
                                        <h2>{formatNumberWithCommas(getEventsDashboard?.data.user_event_attempted)}</h2>


                                    </div>
                                </div>
                                <div className='cardRight'>
                                    <div className='chartInfo'>
                                        <div className='block'>
                                            {getEventsDashboard?.data.event_creation?.map((source, index) => (
                                                <div className='block' key={index}>
                                                    <p>{source.month_year}</p>
                                                    <h2>{source.event_count}</h2>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className='chartWrapper'>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                credits: { enabled: false },
                                                chart: {
                                                    type: 'pie',
                                                },
                                                accessibility: {
                                                    point: {
                                                        valueSuffix: 'Events',
                                                    },
                                                },
                                                tooltip: {
                                                    pointFormat: '<b>{point.y}</b> Events',
                                                },
                                                title: {
                                                    text: '',
                                                },
                                                legend: {
                                                    enabled: true,
                                                    floating: false,
                                                    borderWidth: 0,
                                                    align: 'center',
                                                    layout: 'horizontal',
                                                    verticalAlign: 'bottom',
                                                    padding: 3,
                                                },
                                                plotOptions: {
                                                    pie: {
                                                        shadow: false,
                                                    },
                                                    series: {
                                                        allowPointSelect: true,
                                                        showInLegend: true,
                                                        dataLabels: {
                                                            enabled: false,
                                                            format: '{point.name} - {point.y}%',
                                                        },
                                                    },
                                                },
                                                series: [{
                                                    name: 'Enrollments',
                                                    colorByPoint: true,
                                                    data: getEventsDashboard?.data.event_creation.map(item => ({
                                                        name: item.month_year,
                                                        y: parseInt(item.event_count, 10) || 0, // Ensure the value is a number
                                                    })),
                                                }],
                                            }}
                                        />
                                    </div>
                                </div>
                            </li>

                        </>
                    )}
                </ol>
            </section>

            <section className='tableWrapper'>


                <div className='cardLeft'>
                    <p>Events Enrollments</p>

                </div>
                <div className="flex flex-row justify-between bg-red-400">
                    <div className='chartWrapper'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'column',
                                scrollablePlotArea: {
                                    maxWidth: 300, // Set the minimum width for scrollable plot area
                                }
                            },
                            title: {
                                text: ''
                            },
                            xAxis: {
                                categories: getEventsEnrollment?.data?.top_events?.map((data) => data?.name)
                            },
                            yAxis: {
                                title: {
                                    text: "Top Events Enrollments"
                                },
                            },
                            plotOptions: {
                                column: {
                                    grouping: true, // Default behavior; columns will be placed side by side
                                }
                            },
                            series: [
                                {
                                    name: 'Participants',
                                    color: "#3142A3",
                                    data: getEventsEnrollment?.data?.top_events.map(item => ({
                                        name: item.name,
                                        y: parseInt(item.participants, 10) || 0, // Ensure the value is a number
                                    })),
                                },
                                {
                                    name: 'Total User Attempted',
                                    color: "#59BFEC",
                                    data: getEventsEnrollment?.data?.top_events.map(item => ({
                                        name: item.name,
                                        y: parseInt(item.total_user_attempted, 10) || 0, // Ensure the value is a number
                                    })),
                                }
                            ],
                        }}
                    />

                    </div>
                    <div className='chartWrapper'>
                        <HighchartsReact
                            highcharts={Highcharts}

                            options={{
                                chart: {
                                    type: 'column',

                                },
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: getEventsEnrollment?.data?.event_creators?.map((data) => data?.name)

                                },
                                yAxis: {
                                    title: {
                                        text: "Event Creators Enrollments"
                                    },
                                },

                                series: [{
                                    name: 'Enrollments',
                                    colorByPoint: true,
                                    data: getEventsEnrollment?.data?.event_creators.map(item => ({
                                        name: item.name,
                                        y: parseInt(item.event_count, 10) || 0, // Ensure the value is a number
                                    })),
                                }],
                            }}
                        />
                    </div>

                </div>


                <div className="flex flex-row justify-between">
                    <div className="chartWrapper">
                        <DepartmentEvent
                            yTitle="Event in Department"
                            name="Department"
                            data={getEventsEnrollment?.data?.department_event}
                        />
                    </div>

                    <DepartmentEvent
                        yTitle="Event in Category"
                        name="Category"
                        data={getEventsEnrollment?.data?.category_event}
                    />
                </div>
            </section>

        </div>
    );
};

export default EventsDashboard;
