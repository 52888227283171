import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./common/header";
import SidebarNavigation from "./common/sidebarNavigation";
import useAuth from "./hooks/useAuth";
import "./index.css";
import LoginPage from "./pages/Login";
import routes from "./routes/main";
import { Outlet } from "react-router-dom";
import Overview from "./pages/Overview";


function AppLayout() {
    const isAuthenticated = useAuth("/login");

    return (
        <div className={`App `}>
            <main>
                {isAuthenticated && <SidebarNavigation />}
                <div
                    className={`mainContentWrapper ${isAuthenticated ? "ml-[95px]" : ""}`}
                >
                    {isAuthenticated && <Header />}
                    <div className={isAuthenticated ? "mainWrapper" : ""}>
                        <Outlet />
                    </div>
                </div>
            </main>
        </div>
    );
}

function App() {
    const isAuthenticated = useAuth("/login");

    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<AppLayout />}>
                <Route
                    index
                    element={isAuthenticated ? <Overview /> : <LoginPage />}
                />
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                            isAuthenticated ? (
                                <route.component />
                            ) : (
                                <LoginPage />
                            )
                        }
                    />
                ))}
            </Route>
        </Routes>
    );
}

export default App;