import React, { Component } from 'react';
import '../common/common.css';
// import Highcharts from 'highcharts'

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import { response } from '../mapdata/detailData';


class ProgramDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
          yearChange: '2023-24',
          year2023:'2023-24',
          year2022:'',
          year2021:'',
          enrolmentsOverall: true,
          enrolmentsMinistry: false,
          enrolmentsDirect: false,
          leadyear2023:'leadyear2023',
          leadyear2022:'',
          leadyear2021:'',

          leadconversion2023:'2023-24',
          leadconversion2022:'',
          leadconversion2021:'',
         
          revenuemix2023:'2023-24',
          revenuemix2022:'',
          revenuemix2021:'',
          saveid:''


          
          
        }

    }

    componentDidMount() {
        const currentUrl = window.location.href;
        const urlParts = currentUrl.split('/');
        const lastPart = urlParts[urlParts.length - 1];
        this.setState({saveid : lastPart})
    }





    componentWillUnmount() {

    }

   
    

    LearnSourceMixYearChange = (e) => {
        console.log(e.target.value);
        this.setState({ leadyearChanges: e.target.value });
        if(e.target.value === "2023-24"){
          this.setState({ leadyear2023: e.target.value });
          this.setState({ leadyear2021: ''});
          this.setState({ leadyear2022: ''});
        }
        if(e.target.value === "2022-23"){
          this.setState({ leadyear2022: e.target.value });
          this.setState({ leadyear2023: ''});
          this.setState({ leadyear2021: ''});
        }
        if(e.target.value === "2021-22"){
          this.setState({ leadyear2021: e.target.value });
          this.setState({ leadyear2022: ''});
          this.setState({ leadyear2023: ''});
        }
    }


    leadConversionYearChange = (e) => {
        console.log(e.target.value);
        this.setState({ leadyearChanges: e.target.value });
        if(e.target.value === "2023-24"){
          this.setState({ leadconversion2023: e.target.value });
          this.setState({ leadconversion2021: ''});
          this.setState({ leadconversion2022: ''});
        }
        if(e.target.value === "2022-23"){
          this.setState({ leadconversion2022: e.target.value });
          this.setState({ leadconversion2023: ''});
          this.setState({ leadconversion2021: ''});
        }
        if(e.target.value === "2021-22"){
          this.setState({ leadconversion2021: e.target.value });
          this.setState({ leadconversion2022: ''});
          this.setState({ leadconversion2023: ''});
        }
    }
    

    
    
    revenueSourceMixYearChange = (e) => {
        if(e.target.value === "2023-24"){
          this.setState({ revenuemix2023: e.target.value });
          this.setState({ revenuemix2021: ''});
          this.setState({ revenuemix2022: ''});
        }
        if(e.target.value === "2022-23"){
          this.setState({ revenuemix2022: e.target.value });
          this.setState({ revenuemix2023: ''});
          this.setState({ revenuemix2021: ''});
        }
        if(e.target.value === "2021-22"){
          this.setState({ revenuemix2021: e.target.value });
          this.setState({ revenuemix2022: ''});
          this.setState({ revenuemix2023: ''});
        }
    }
    

    goBack = () => {
        window.history.back(); // This will navigate back to the previous page in the history stack
      };

    render() {

        
        return (
            <div className='detailsWrapper'>
                 <section>
                    <div className='infoWrapper'>
                        <div className='pageTitle'>
                            <h1><i className='fa fa-chevron-left' onClick={this.goBack}></i>{response[this.state.saveid]?.programData[0].name}</h1>
                        </div>
                        <div className='pageActions'>
                            <div>
                                <label>Start Date:</label>{response[this.state.saveid]?.programData[0].startdate}
                            </div>
                            <div>
                                <label>Completion Date:</label>{response[this.state.saveid]?.programData[0].completiondate}
                            </div>
                        </div>
                    </div>
                </section>
                <section className='cardInfoWrapper'>
                   
                    <ol>
                            {this.state.yearChange && (
                                <>
                                    <li>
                                        <div className='cardLeft'>
                                            <p>Revenue</p>
                                            <h2>{response[this.state.saveid]?.enrollmentsData[0]?.revenue}</h2>
                                            <div className='progressWrapper'>
                                                <div className='progressInfo'>
                                                    <div><span>{response[this.state.saveid]?.enrollmentsData[0].revenueprogress}%</span>Achieved</div>
                                                    <div>Target : {response[this.state.saveid]?.enrollmentsData[0].revenuetarget}</div>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" style={{ width: `${response[this.state.saveid]?.enrollmentsData[0].revenueprogress}%` }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cardLeft'>
                                            <p>Enrolments</p>
                                            <h2>{response[this.state.saveid]?.enrollmentsData[0]?.enrollments}</h2>
                                            <div className='progressWrapper'>
                                                <div className='progressInfo'>
                                                    <div><span>{response[this.state.saveid]?.enrollmentsData[0].enrollmentsprogress}%</span>Achieved</div>
                                                    <div>Target : {response[this.state.saveid]?.enrollmentsData[0].enrollmentstarget}</div>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" style={{ width: `${response[this.state.saveid]?.enrollmentsData[0].enrollmentsprogress}%` }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cardLeft'>
                                            <p>CPA%</p>
                                            <h2>{response[this.state.yearChange]?.enrollmentsData[0]?.cpa}%</h2>
                                            <div className='progressWrapper'>
                                                <div className='progressInfo'>
                                                    <div><span>{response[this.state.saveid]?.enrollmentsData[0].cpaprogress}%</span>Achieved</div>
                                                    <div>Target : {response[this.state.saveid]?.enrollmentsData[0].cpatarget}%</div>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" style={{ width: `${response[this.state.yearChange]?.enrollmentsData[0].cpaprogress}%` }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cardLeft'>
                                            <p>Conversion Rate</p>
                                            <h2>{response[this.state.saveid]?.enrollmentsData[0]?.conversionrate}%</h2>
                                            <div className='progressWrapper'>
                                                <div className='progressInfo'>
                                                    <div><span>{response[this.state.saveid]?.enrollmentsData[0].conversionrateprogress}%</span>Achieved</div>
                                                    <div>Target : {response[this.state.saveid]?.enrollmentsData[0].conversionratetarget}%</div>
                                                </div>
                                                <div className="progress">
                                                    <div className="progress-bar" style={{ width: `${response[this.state.yearChange]?.enrollmentsData[0].conversionrateprogress}%` }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </li>
                                    <li>
                                        <div className='cardRight'>
                                        <div className='chartInfo'>
                                            <div className='block'>
                                                <p>Budget</p>
                                                <h2>{response[this.state.saveid]?.enrollmentsData[0].budget}</h2>
                                            </div>
                                            <div className='block'>
                                                <p>Spends</p>
                                                <h2>{response[this.state.saveid]?.enrollmentsData[0].spends}</h2>
                                            </div>
                                            <div className='block'>
                                                <p>%Exhausted</p>
                                                <h2>{response[this.state.saveid]?.enrollmentsData[0].exhausted}</h2>
                                            </div>
                                        </div>
                                        </div>    
                                    </li>
                                </>
                            )}
                        </ol>
                </section>

                <section className='chartWrapper'>
                      <div className='left'>
                        <div className='chartheading'>
                                <h3>Revenue Source Mix</h3>
                                <div>
                                <select onChange={(e) => this.revenueSourceMixYearChange(e)} >
                                    <option value={"2023-24"}>2023-24 (Base Year)</option>
                                    <option value={"2022-23"}>2022-23</option>
                                    <option value={"2021-22"}>2021-22</option>
                                </select>
                                </div>
                            </div>
                            {this.state.revenuemix2023 &&
                                     <HighchartsReact
                                     highcharts={Highcharts}
                                     options={{
                                         credits: { enabled: false },
                                         chart: {
                                             type: 'pie',
                                            
                                         },
                                     
                                         accessibility: {
                                             point: {
                                                 valueSuffix: '%'
                                             }
                                         },
                                         tooltip: {
                                             pointFormat: '<b>{point.y}</b>'
                                         },
                                         title: {
                                             text: ''
                                         },
                                         legend: {
                                             enabled: true,
                                             floating: false,
                                             borderWidth: 0,
                                             align: 'bottom',
                                             layout: 'horizontal',
                                             verticalAlign: 'bottom',
                                             padding: 3,
                                             
                                             // labelFormatter: function() {
                                             //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                             // }
                                         },
                                         plotOptions: {
                                             series: {
                                                 allowPointSelect: true,
                                                 showInLegend: true,
                                                 dataLabels: {
                                                     enabled: true,
                                                     format: '{point.name} - {point.y}',
                                     
                                                 },
                                     
                                             }
                                         },
                                         series: response[this.state.saveid]?.revenueSourceMix2023,
                                     
                                     
                                     
                                     }}
                                 />
                                }



                            {this.state.saveid && this.state.revenuemix2022 &&
                             <HighchartsReact
                             highcharts={Highcharts}
                             options={{
                                 credits: { enabled: false },
                                 chart: {
                                     type: 'pie',
                             
                                 },
                             
                                 accessibility: {
                                     point: {
                                         valueSuffix: '%'
                                     }
                                 },
                                 tooltip: {
                                     enabled: false,
                                     pointFormat: '<b>{point.y}</b>'
                                 },
                                 title: {
                                     text: ''
                                 },
                                 legend: {
                                    enabled: true,
                                    floating: false,
                                    borderWidth: 0,
                                    align: 'bottom',
                                    layout: 'horizontal',
                                    verticalAlign: 'bottom',
                                    padding: 3,
                                     // labelFormatter: function() {
                                     //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                     // }
                                 },
                                 plotOptions: {
                                     series: {
                                         allowPointSelect: true,
                                         showInLegend: true,
                                         dataLabels: {
                                             enabled: true,
                                             format: '{point.name}: {point.y:,.0f}'
                             
                                         },
                             
                                     }
                                 },
                                 series: response[this.state.saveid]?.revenueSourceMix2022,
                             }}
                         />
                        }

                            {this.state.saveid && this.state.revenuemix2021 &&
                             <HighchartsReact
                             highcharts={Highcharts}
                             options={{
                                 credits: { enabled: false },
                                 chart: {
                                     type: 'pie',
                             
                                 },
                             
                                 accessibility: {
                                     point: {
                                         valueSuffix: '%'
                                     }
                                 },
                                 tooltip: {
                                     enabled: false,
                                     pointFormat: '<b>{point.y}</b>'
                                 },
                                 title: {
                                     text: ''
                                 },
                                 legend: {
                                    enabled: true,
                                    floating: false,
                                    borderWidth: 0,
                                    align: 'bottom',
                                    layout: 'horizontal',
                                    verticalAlign: 'bottom',
                                    padding: 3,
                                     // labelFormatter: function() {
                                     //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                     // }
                                 },
                                 plotOptions: {
                                     series: {
                                         allowPointSelect: true,
                                         showInLegend: true,
                                         dataLabels: {
                                             enabled: true,
                                             format: '{point.name}: {point.y:,.0f}'
                             
                                         },
                             
                                     }
                                 },
                                 series: response[this.state.saveid]?.revenueSourceMix2021,
                             }}
                         />
                        }
 
                    </div>                              
                      <div className='middle'>
                      <div className='chartheading'>
                                    <h3>Conversion</h3>
                                    <select onChange={(e) => this.LearnSourceMixYearChange(e)} >
                                        <option value={"2023-24"}>2023-24 (Base Year)</option>
                                        <option value={"2022-23"}>2022-23</option>
                                        <option value={"2021-22"}>2021-22</option>
                                    </select>
                                </div>
                                {this.state.leadyear2023 &&
                                    <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                       chart: {
                                           type: 'column',
                                           
                                        },
                                        credits: {enabled: false},
                                        title: {
                                            text: ''
                                        },
                                        xAxis: {
                                            type: 'category',
                                            categories: [
                                                'Campaigns', 'Events', 'Referrals', 'Ministry','Transfers'
                                            ],
                                            max: 4,
                                            tickLength: 0,
                                            scrollbar: {
                                              enabled: true,
                                              showFull:false
                                            },
                                        },
                                        yAxis:{
                                           title:{
                                               text:"Conversion"
                                            },
                                        },
                                        plotOptions: {
                                            column: {
                                                stacking: 'normal',
                                          dataLabels: {
                                             enabled: false
                                          }
                                      }
                                  },
                                        series: response[this.state.saveid]?.conversionData2023
                                    }}
                                />
                                }


                                {this.state.leadyear2022 &&
                                     <HighchartsReact
                                     highcharts={Highcharts}
                                     options={{
                                        chart: {
                                            type: 'column',
                                            
                                         },
                                         credits: {enabled: false},
                                         title: {
                                             text: ''
                                         },
                                         xAxis: {
                                             type: 'category',
                                             categories: [
                                                'Campaigns', 'Events', 'Referrals', 'Ministry','Transfers'
                                             ],
                                             max: 4,
                                             tickLength: 0,
                                             scrollbar: {
                                               enabled: true,
                                               showFull:false
                                             },
                                         },
                                         yAxis:{
                                            title:{
                                                text:"Conversion"
                                             },
                                         },
                                         plotOptions: {
                                            column: {
                                                stacking: 'normal',
                                          dataLabels: {
                                             enabled: false
                                          }
                                      }
                                  },
                                         series: response[this.state.saveid]?.conversionData2022
                                     }}
                                 />
                                }


                                    {this.state.leadyear2021 &&
                                     <HighchartsReact
                                     highcharts={Highcharts}
                                     options={{
                                        chart: {
                                            type: 'column',
                                            
                                         },
                                         credits: {enabled: false},
                                         title: {
                                             text: ''
                                         },
                                         xAxis: {
                                             type: 'category',
                                             categories: [
                                                'Campaigns', 'Events', 'Referrals', 'Ministry','Transfers'
                                             ],
                                             max: 4,
                                             tickLength: 0,
                                             scrollbar: {
                                               enabled: false,
                                               showFull:false
                                             },
                                         },
                                         yAxis:{
                                            title:{
                                                text:"Conversion"
                                             },
                                         },
                                         plotOptions: {
                                            column: {
                                                stacking: 'normal',
                                          dataLabels: {
                                             enabled: false
                                          }
                                      }
                                  },
                                         series: response[this.state.saveid]?.conversionData2021
                                     }}
                                 />
                                }
        
                     </div>                              
                      <div className='right'>
                      <div className='chartheading'>
                            <h3>Revenue</h3>
                            <select onChange={(e) => this.leadConversionYearChange(e)} >
                                <option value={"2023-24"}>2023-24 (Base Year)</option>
                                <option value={"2022-23"}>2022-23</option>
                                <option value={"2021-22"}>2021-22</option>
                            </select>
                        </div>
                        {this.state.leadconversion2023 &&
                        <HighchartsReact
                             highcharts={Highcharts}
                             options={{
                                 credits: { enabled: false },
                                 chart: {
                                     type: 'pie',
                             
                                 },
                             
                                 accessibility: {
                                     point: {
                                         valueSuffix: '%'
                                     }
                                 },
                                 tooltip: {
                                     enabled: false,
                                     pointFormat: '<b>{point.y}</b>'
                                 },
                                 title: {
                                     text: ''
                                 },
                                 legend: {
                                    enabled: true,
                                    floating: false,
                                    borderWidth: 0,
                                    align: 'center',
                                    layout: 'horizontal',
                                    verticalAlign: 'bottom',
                                    padding: 3,
                                     labelFormatter: function() {
                                       return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + ')<br/>';
                                     }
                                 },
                                 plotOptions: {
                                     series: {
                                         allowPointSelect: true,
                                         showInLegend: true,
                                         dataLabels: {
                                             enabled: false,
                                             format: '{point.name}: {point.y:,.0f}'
                             
                                         },
                             
                                     }
                                 },
                                 series: response[this.state.saveid]?.revenue2023,
                             }}
                         />
                        }


                                {this.state.leadconversion2022 &&
                                      <HighchartsReact
                                      highcharts={Highcharts}
                                      options={{
                                          credits: { enabled: false },
                                          chart: {
                                              type: 'pie',
                                      
                                          },
                                      
                                          accessibility: {
                                              point: {
                                                  valueSuffix: '%'
                                              }
                                          },
                                          tooltip: {
                                              enabled: false,
                                              pointFormat: '<b>{point.y}</b>'
                                          },
                                          title: {
                                              text: ''
                                          },
                                          legend: {
                                             enabled: true,
                                             floating: false,
                                             borderWidth: 0,
                                             align: 'center',
                                             layout: 'horizontal',
                                             verticalAlign: 'bottom',
                                             padding: 3,
                                              labelFormatter: function() {
                                                return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + ')<br/>';
                                              }
                                          },
                                          plotOptions: {
                                              series: {
                                                  allowPointSelect: true,
                                                  showInLegend: true,
                                                  dataLabels: {
                                                      enabled: false,
                                                      format: '{point.name}: {point.y:,.0f}'
                                      
                                                  },
                                      
                                              }
                                          },
                                          series: response[this.state.saveid].revenue2022,
                                      }}
                                  />
                                }


                                    {this.state.leadconversion2021 &&
                                      <HighchartsReact
                                      highcharts={Highcharts}
                                      options={{
                                          credits: { enabled: false },
                                          chart: {
                                              type: 'pie',
                                      
                                          },
                                      
                                          accessibility: {
                                              point: {
                                                  valueSuffix: '%'
                                              }
                                          },
                                          tooltip: {
                                              enabled: false,
                                              pointFormat: '<b>{point.y}</b>'
                                          },
                                          title: {
                                              text: ''
                                          },
                                          legend: {
                                             enabled: true,
                                             floating: false,
                                             borderWidth: 0,
                                             align: 'center',
                                             layout: 'horizontal',
                                             verticalAlign: 'bottom',
                                             padding: 3,
                                              labelFormatter: function() {
                                                return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + ')<br/>';
                                              }
                                          },
                                          plotOptions: {
                                              series: {
                                                  allowPointSelect: true,
                                                  showInLegend: true,
                                                  dataLabels: {
                                                      enabled: false,
                                                      format: '{point.name}: {point.y:,.0f}'
                                      
                                                  },
                                      
                                              }
                                          },
                                          series: response[this.state.saveid].revenue2021,
                                      }}
                                  />
                                }
        
                           
                    </div>                              
                </section>   

                
                <section className='chartWrapper'>
                  <div className='left singleChart'>
                  <div className='chartheading'>
                    <h3>Platform wise lead conversion and spends</h3>
                </div>
                   {/* {JSON.stringify(response.year2023.programWiseEnrollment)}              */}
                <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'column',
                                    scrollablePlotArea: {
                                        maxWidth: response[this.state.saveid]?.programWiseEnrollment?.category?.length , // Set the minimum width for scrollable plot area
                                    }
                                },
                                title: {
                                    text: ''
                                },
                               xAxis: {
                                  type: 'category',
                                  max: 6,
                                  tickLength: 0,
                                  scrollbar: {
                                    enabled: true,
                                    showFull:false
                                  },
                                  crosshair: true,
                                  categories: response[this.state.saveid]?.programWiseEnrollment?.category,
                                  labels: {
                                      autoRotation: [-25,0],
                                      rotation:0,
                                      style: {
                                          fontSize: '10px',
                                     }
                                  },
                              },

                             
                              yAxis: {
                                  title: {
                                      text: 'Platform wise lead conversion and spends'
                                  },
                                  tickLength: 0,
                                  scrollbar: {
                                    enabled: false,
                                    showFull:false
                                  },
                                  
                              },
                              
                              legend: {
                                enabled: true
                            },

                            
                            series: response[this.state.saveid]?.programWiseEnrollment?.data,
                             
                        }}
                        />             
                    </div>
                </section>   


              


               


            </div>
        )
    }
}



export default ProgramDetail;