import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGetTopSponsorQuery, useGetTopSpecializationsQuery } from '../redux/slice/apiSlice';
import './Table.css'
const Top2SponsorsChart = () => {
  const [year, setYear] = useState(null)

  const { data: response, error: error } = useGetTopSponsorQuery()
  const { data: specializationsResp, error: specializationsError } = useGetTopSpecializationsQuery(
    {
      joining_semester: year
    }
  )
  const topSponsorData = response?.data;
  const specializationsData = specializationsResp?.data;
  const handleLeadSourceYearChange = (e) => {
    setYear(e.target.value)
  }
  const options = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Sponsorship by MOHE & MEC'
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      categories: topSponsorData?.top_sponsor.map((data) => data?.Certification),
      title: {
        text: 'Sponsorship Type'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Number of Students'
      }
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },

    series: [{
      name: 'MEC',
      data: topSponsorData?.top_sponsor.map((data) => data?.user_count.MEC)
    }, {
      name: 'MOHE',
      data: topSponsorData?.top_sponsor.map((data) => data?.user_count.MOHE)
    }]
  };

  return (
    <div>
      <div className="m-2">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      </div>
      <div className="flex gap-5">
        <div className="left bg-white p-2 m-2">
          <table className="table-auto border border-gray-300 w-full fixed_header">
            <thead>
              <tr className="bg-blue-500 text-black">
                <th className="border border-gray-300 px-4 py-2">Sponsor</th>
                <th className="border border-gray-300 px-4 py-2">Bachelor</th>
                <th className="border border-gray-300 px-4 py-2">Diploma</th>
                <th className="border border-gray-300 px-4 py-2">GFP-Fnd</th>
                <th className="border border-gray-300 px-4 py-2">Masters</th>
                <th className="border border-gray-300 px-4 py-2">Total</th>
              </tr>
            </thead>
            <tbody className='max-h-[315px]'>
              {
                topSponsorData?.total_sponsors.map((data) => <tr>
                  <td className="border border-gray-300 px-4 py-2">{data?.sponsor}</td>
                  <td className="border border-gray-300 px-4 py-2">{data?.user_count.Bachelor}</td>
                  <td className="border border-gray-300 px-4 py-2">{data?.user_count.Diploma}</td>
                  <td className="border border-gray-300 px-4 py-2">{data?.user_count['GFP-Fnd']}</td>
                  <td className="border border-gray-300 px-4 py-2">{data?.user_count?.Masters}</td>
                  <td className="border border-gray-300 px-4 py-2">{data?.total_user}</td>
                </tr>)
              }

            </tbody>
          </table>
        </div>
        <div className="right bg-white p-2 m-2">
          <div className="flex flex-col">
            <select
              onChange={handleLeadSourceYearChange}
              className="border border-gray-300 rounded-md p-2 text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 mb-6 py-2"
            >
              <option value="">Select Year</option>
              {specializationsData?.semesters.map((sem) => (
                <option key={sem} value={sem}>
                  {sem}
                </option>
              ))}
            </select>

            <table className="table-auto border border-gray-300 w-full fixed_header">
              <thead>
                <tr className="bg-blue-500 text-black">
                  <th className="border border-gray-300 px-4 py-2">Specialisation</th>
                  <th className="border border-gray-300 px-4 py-2">Bachelor</th>
                  <th className="border border-gray-300 px-4 py-2">Diploma</th>
                  <th className="border border-gray-300 px-4 py-2">GFP-Fnd</th>
                  <th className="border border-gray-300 px-4 py-2">Masters</th>
                  <th className="border border-gray-300 px-4 py-2">Total</th>
                </tr>
              </thead>
              <tbody className='max-h-[260px]'>
                {
                  specializationsData?.total_sponsors.map((data) => <tr>
                    <td className="border border-gray-300 px-4 py-2">{data?.specialisation}</td>
                    <td className="border border-gray-300 px-4 py-2">{data?.user_count.Bachelor}</td>
                    <td className="border border-gray-300 px-4 py-2">{data?.user_count.Diploma}</td>
                    <td className="border border-gray-300 px-4 py-2">{data?.user_count['GFP-Fnd']}</td>
                    <td className="border border-gray-300 px-4 py-2">{data?.user_count?.Masters}</td>
                    <td className="border border-gray-300 px-4 py-2">{data?.total_user}</td>
                  </tr>)
                }

              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>


  );
};

export default Top2SponsorsChart;