import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.svg';
import './common.css';
import { setTitle } from '../redux/slice/pageTitleSlice';
import { useAppDispatch } from '../redux/store';

const SidebarNavigation = () => {

  const navItems = [
    { title: 'Overview', path: '/overview', icon: 'grid_view' },
    { title: 'CRM', path: '/crm', icon: 'diversity_3' }, // Added
    { title: 'Student', path: '/student-course', icon: 'school' }, // Added
    { title: 'Revenue', path: '/revenue', icon: 'payments' },
    // { title: 'Enrollments', path: '/enrollments', icon: 'school' }, old Enrollments Page Template
    { title: 'Programmes', path: '/programmes', icon: 'book_2' },
    { title: 'Employability', path: '/employability', icon: 'work' },
    { title: 'Event', path: '/event', icon: 'event' },
    { title: 'Create User', path: '/create-user', icon: 'person_add' }, // New item
    { title: 'Finanace', path: '/finance', icon: 'money' }, // New item
    { title: 'Community', path: '/community', icon: 'communities' }, // New item

  ];
  const dispatch = useAppDispatch();
  const handleClick = (title) => {
    dispatch(setTitle(title));
  };

  return (
    <aside>
      <div className='logo'><img alt='logo' src={logo} /></div>
      <nav>
        <ul>
          {navItems.map((item, index) => (
            <li key={index} title={item.title}>
              <NavLink
                key={item.path}
                to={item.path}
                onClick={()=> handleClick(item?.title)}
              >
                <span className="material-symbols-outlined">{item.icon}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
}

export default SidebarNavigation;
