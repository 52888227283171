import React, { useState, useEffect } from 'react';
import '../common/common.css';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { response } from '../mapdata/enrollmentsData';
import { useEnrollmentsYearSourceQuery, useEnrollmentsSourceEnrollmentQuery, useGetEnrollmentsQuery, useGetLeadConversionQuery, useEnrollmentsYearQuery } from '../redux/slice/apiSlice';
import { formatNumberWithCommas } from '../utils/utils';

const Enrolments = () => {
    const [yearChange, setYearChange] = useState('2023-24');
    const [leadConversionSelectYear, setLeadConversionSelectYear] = useState(null);
    const [leadSourceSelectYear, setLeadSourecSelectYear] = useState(null);
    const [selectedEnrollmentSource, setselectedEnrollmentSource] = useState(null);
    const { data: getEnrollments, error: getEnrollmentsError, refetch: refetchEnrollment } = useEnrollmentsYearQuery(selectedEnrollmentSource);
    const { data: enrollmentYearSourceData, error: enrollmentsYearSourceError, refetch: refetchLeadSource, } = useEnrollmentsYearSourceQuery(leadSourceSelectYear);
    const { data: enrollmentsSourceData, isLoading: enrollmentsSourceDataLoading, error: enrollmentsSourceEnrollmentError } = useEnrollmentsSourceEnrollmentQuery();
    const { data: programWiseEnrollmentData, error: programWiseEnrollmentError, refetch: refetchEnrollmentCategory } = useGetEnrollmentsQuery();
    const { data: leadConversionEnrollmentData, error: leadConversionEnrollmentError, refetch: refetchLeadConversion } = useGetLeadConversionQuery(leadConversionSelectYear);
    const currentYear = new Date().getFullYear();
    const currentAcademicYear = `${currentYear - 1}-${currentYear.toString().slice(-2)}`;


    const handleLeadConversionYearChange = (e) => {
        const selectedYear = e.target.value;
        setLeadConversionSelectYear(selectedYear);
        if (leadConversionSelectYear != null) {
            refetchLeadConversion()
        }
    };

    const handleLeadSourceYearChange = (e) => {
        const selectedYear = e.target.value;
        setLeadSourecSelectYear(selectedYear);
    };

    const handleEnrollmentCategory = (e) => {
        const selectCotegory = e.target.value;
        setselectedEnrollmentSource(selectCotegory)
    }

    useEffect(() => {
        if (selectedEnrollmentSource != null) {
            refetchEnrollment()
        }
    }, [selectedEnrollmentSource])

    useEffect(() => {
        if (leadSourceSelectYear != null) {
            refetchLeadSource()
        }
    }, [leadSourceSelectYear])


    return (
        <div className='enrollmentsWrapper'>
            <section>
                <div className='infoWrapper'>
                    <div className='pageTitle'>
                        <h1>Enrollments</h1>
                    </div>
                    <div className='pageActions'>
                        <label>Year</label>
                        <select onChange={(e) => this.YearChange(e)} >
                            <option value={"2023-24"}>2023-24 (Base Year)</option>
                            <option value={"2022-23"}>2022-23</option>
                            <option value={"2021-22"}>2021-22</option>
                        </select>
                    </div>
                </div>
            </section>
            <section className='cardInfoWrapper'>
                <ol>
                    <>
                        <li>
                            <div className='cardLeft'>
                                <p>Total Enrolments</p>
                                <h2>{formatNumberWithCommas(enrollmentsSourceData?.data?.total_enrollment)}</h2>
                                <p>Increase by <b>{formatNumberWithCommas(enrollmentsSourceData?.data?.percentage_increased)}%</b> from last year</p>
                                <div className='progressWrapper'>
                                    <div className='progressInfo'>
                                        <div><span>{response[yearChange].enrollmentsData[0].achieved}</span>Achieved</div>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: `${response[yearChange]?.enrollmentsData[0].achieved}` }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className='cardRight'>
                                <div className='chartWrapper'>
                                    {enrollmentsSourceData?.data?.pie_chart_data ? (
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                credits: { enabled: false },
                                                chart: {
                                                    type: 'pie',
                                                    height: 300,
                                                },
                                                accessibility: {
                                                    point: {
                                                        valueSuffix: '%',
                                                    },
                                                },
                                                tooltip: {
                                                    pointFormat: '<b>{point.y}</b>',
                                                },
                                                title: {
                                                    text: '',
                                                },
                                                legend: {
                                                    enabled: true,
                                                    floating: false,
                                                    borderWidth: 0,
                                                    align: 'center',
                                                    layout: 'horizontal',
                                                    verticalAlign: 'bottom',
                                                    padding: 3,
                                                },
                                                plotOptions: {
                                                    pie: {
                                                        shadow: false,
                                                        center: ['50%', '50%'],
                                                        innerSize: '50%',
                                                    },
                                                    series: {
                                                        allowPointSelect: true,
                                                        showInLegend: true,
                                                        dataLabels: {
                                                            enabled: false,
                                                            format: '{point.name}',
                                                        },
                                                    },
                                                },
                                                series: [{
                                                    name: 'Enrollments',
                                                    colorByPoint: true,
                                                    data: enrollmentsSourceData?.data?.pie_chart_data?.map(item => ({
                                                        name: item.enrollment_source,
                                                        y: parseInt(item.total_enrollment, 10) || 0, // Ensure the value is a number
                                                    })),
                                                }],
                                            }}

                                        />
                                    ) : (
                                        <p>Loading data...</p>
                                    )}
                                </div>
                                <div className='chartInfo'>
                                    {enrollmentsSourceData?.data?.pie_chart_data?.map((source, index) => (
                                        <div className='block' key={index}>
                                            <span>{source.enrollment_source}</span>
                                            <h2>{formatNumberWithCommas(source.total_enrollment)}</h2>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </li>
                        <li>
                            <div className='chartInfo'>
                                {enrollmentsSourceData?.data?.course_data?.map((source, index) => (
                                    <div className='cardLeft' key={index}>
                                        <span>{source.program_name}</span>

                                        <h2>{formatNumberWithCommas(source.total_enrollment)}</h2>
                                    </div>
                                ))}
                            </div>
                            <div className='chartWrapper'>
                                {enrollmentsSourceData?.data && enrollmentsSourceData?.data?.course_data ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={{
                                            credits: { enabled: false },
                                            chart: {
                                                type: 'pie',
                                                height: 300,
                                            },
                                            accessibility: {
                                                point: {
                                                    valueSuffix: '%'
                                                }
                                            },
                                            tooltip: {
                                                pointFormat: '<b>{point.y}</b>'
                                            },
                                            title: {
                                                text: ''
                                            },
                                            legend: {
                                                enabled: true,
                                                floating: false,
                                                borderWidth: 0,
                                                align: 'center',
                                                layout: 'horizontal',
                                                verticalAlign: 'bottom',
                                                padding: 3,
                                            },
                                            plotOptions: {
                                                pie: {
                                                    shadow: false,
                                                    center: ['50%', '50%'],
                                                },
                                                series: {
                                                    allowPointSelect: true,
                                                    showInLegend: true,
                                                    dataLabels: {
                                                        enabled: false,
                                                        format: '{point.name}',
                                                    },
                                                }
                                            },
                                            series: [{
                                                name: 'Enrollments',
                                                colorByPoint: true,
                                                data: enrollmentsSourceData?.data?.course_data.map(item => ({
                                                    name: item.program_name,
                                                    y: parseInt(item.total_enrollment, 10) || 0,
                                                })),
                                            }],
                                        }}

                                    />

                                ) : (
                                    <p>Loading data...</p>
                                )}
                            </div>

                        </li>
                    </>
                </ol>
            </section>

            <section className='chartWrapper'>
                <div className='left'>
                    <div className='chartheading'>
                        <h3>Enrollments</h3>

                        <select onChange={handleEnrollmentCategory}>
                            <option value="">Select Sources</option> {/* Default option */}
                            {getEnrollments && getEnrollments?.data ? (
                                getEnrollments?.data?.enrollment_source?.map((enrollment_source) => (
                                    <option key={enrollment_source} value={enrollment_source}>
                                        {enrollment_source}
                                    </option>
                                ))
                            ) : (
                                <option disabled>No years available</option>
                            )}
                        </select>


                    </div>


                    {getEnrollments && getEnrollments.data &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: getEnrollments?.data?.enrollment?.map(item => item.year)

                                },
                                yAxis: {
                                    title: {
                                        text: "Enrollments",

                                    },
                                },

                                series: [{
                                    name: 'Targeted',
                                    colorByPoint: true,
                                    data: getEnrollments?.data?.enrollment?.map(item => ({
                                        name: item.enrollment_accepted,
                                        y: parseInt(item.total_enrollment, 10) || 0, // Ensure the value is a number
                                    })),
                                }],

                            }}
                        />
                    }
                </div>

                <div className='middle'>
                    <div className='chartheading'>
                        <h3>Lead Source Mix</h3>

                        <select onChange={handleLeadSourceYearChange}>
                            <option value="">Select Year</option>
                            {enrollmentYearSourceData && enrollmentYearSourceData?.data ? (
                                enrollmentYearSourceData?.data?.year.map((year) => (
                                    <option key={year} value={year}>
                                        {year} {year === currentAcademicYear ? "(Base Year)" : ""}
                                    </option>
                                ))
                            ) : (
                                <option disabled>No years available</option>
                            )}
                        </select>
                    </div>
                    {enrollmentYearSourceData && enrollmentYearSourceData.data ? (<HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'pie',

                            },

                            accessibility: {
                                point: {
                                    valueSuffix: '%'
                                }
                            },
                            tooltip: {
                                pointFormat: '<b>{point.y}</b>'
                            },
                            title: {
                                text: ''
                            },
                            legend: {
                                enabled: true,
                                floating: false,
                                borderWidth: 0,
                                align: 'bottom',
                                layout: 'horizontal',
                                verticalAlign: 'bottom',
                                padding: 3,

                                // labelFormatter: function() {
                                //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                // }
                            },
                            plotOptions: {
                                series: {
                                    allowPointSelect: true,
                                    showInLegend: true,
                                    dataLabels: {
                                        enabled: true,
                                        format: '{point.name} {point.y}',

                                    },

                                }
                            },

                            series: [{
                                name: 'Enrollments',
                                colorByPoint: true,
                                data: enrollmentYearSourceData?.data?.enrollment.map(item => ({
                                    name: item.enrollment_source,
                                    y: parseInt(item.total_enrollment, 10) || 0, // Ensure the value is a number
                                })),
                            }],



                        }}
                    />) : <p>Loading Data...</p>

                    }
                </div>
                <div className='right'>
                    <div className='chartheading'>
                        <h3>Lead Conversion</h3>
                        <select onChange={handleLeadConversionYearChange}>
                            {leadConversionEnrollmentData && leadConversionEnrollmentData.data ? (
                                leadConversionEnrollmentData?.data?.year.map((year) => (
                                    <option key={year} value={year}>
                                        {year} {year === currentAcademicYear ? "(Base Year)" : ""}
                                    </option>
                                ))
                            ) : (
                                <option disabled>No years available</option>
                            )}
                        </select>
                    </div>




                    {leadConversionEnrollmentData && leadConversionEnrollmentData.data ? (<HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            chart: {
                                type: 'column',

                            },
                            credits: { enabled: false },
                            title: {
                                text: ''
                            },
                            xAxis: {
                                categories: leadConversionEnrollmentData?.data?.enrollment?.map((data) => data?.enrollment_source)

                            },
                            yAxis: {
                                title: {
                                    text: "Lead Conversion"
                                },
                            },

                            series: [{
                                name: 'Enrollments',
                                colorByPoint: true,
                                data: leadConversionEnrollmentData?.data?.enrollment.map(item => ({
                                    name: item.enrollment_source,
                                    y: parseInt(item.total_enrollment, 10) || 0, // Ensure the value is a number
                                })),
                            }],
                        }}
                    />) : <p>Loading data ...</p>

                    }
                </div>
            </section>


            <section className='chartWrapper'>
                <div className='left singleChart'>
                    <div className='chartheading'>
                        <h3>Programme Wise Enrolments</h3>
                    </div>

                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'column',
                                scrollablePlotArea: {
                                    maxWidth: programWiseEnrollmentData?.data?.enrollment?.length - 1, // Set the minimum width for scrollable plot area
                                }
                            },
                            title: {
                                text: ''
                            },
                            xAxis: {
                                type: 'category',
                                max: programWiseEnrollmentData?.data?.enrollment?.length - 1,
                                tickLength: 0,
                                scrollbar: {
                                    enabled: true,
                                    showFull: false
                                },
                                crosshair: true,
                                categories: programWiseEnrollmentData?.data?.enrollment?.map((data) => data?.program_name),
                                labels: {
                                    autoRotation: [-25, 0],
                                    rotation: 0,
                                    style: {
                                        fontSize: '10px',
                                    }
                                },
                            },


                            yAxis: {
                                title: {
                                    text: 'Programme Wise Enrolments(10 thousands)'
                                },
                                tickLength: 0,
                                scrollbar: {
                                    enabled: false,
                                    showFull: false
                                },

                            },

                            legend: {
                                enabled: true
                            },

                            plotOptions: {
                                column: {
                                    stacking: 'normal',
                                    dataLabels: {
                                        enabled: false
                                    }
                                }
                            },

                            series: [{
                                name: 'Enrollments',
                                colorByPoint: true,
                                data: programWiseEnrollmentData?.data?.enrollment.map(item => ({
                                    name: item.enrollment_source,
                                    y: parseInt(item.total_enrollment, 10) || 0, // Ensure the value is a number
                                })),
                            }],

                        }}
                    />
                </div>
            </section>
        </div>
    )
}

export default Enrolments;