const response = {

  "2023-24": {

    "enrollmentsData": [
      {
        "totalenrollments": "4312",
        "increaseby": "-5%",
        "achieved": "77%",
        "ministryenrolments": "2700",
        "other":"1612",
        "bachelor": "16",
        "diploma": "16",
        "master": "7",
        "gfp":"2",
        
      },
    ],

    "series": [
      {
        innerSize: '50%',
        size: '100%',
        "data": [
          {
            "name": "Direct",
            "y":  2700,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 1612,
            "color": "#3142A3"
          },
        ]
      }
    ],

    "seriespie": [
      {
        size: '100%',
        "data": [
          {
            "name": "Bachelor",
            "y":  16,
            "color": "#59BFEC"
          },
          {
            "name": "Diploma",
            "y": 16,
            "color": "#3142A3"
          },
          {
            "name": "Master",
            "y": 7,
            "color": "#75D284"
          },
          {
            "name": "GFP",
            "y": 2,
            "color": "#008B35"
          },
        ]
      }
    ],
    
    "enrolmentsOverall": [
      {
        "name": "Achieved",
        "data": [4450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],

    "enrolmentsMinistry": [
      {
        "name": "Achieved",
        "data": [1750, 1936, 2529, 2248,3308]
      },
      {
        "name": "Targeted",
        "data": [2700, 2904, 2701, 3372,2702]
      }
    ],
    "enrolmentsDirect": [
      {
        "name": "Achieved",
        "data": [2700, 2904, 2701, 3372,2702]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],

    "leadSourceMixSeriesyear2024pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  162,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 2700,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 403,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 484,
            "color": "#75D284"
          },
          {
            "name": "Events",
            "y": 323,
            "color": "#008B35"
          },
          {
            "name": "Transfers",
            "y": 242,
            "color": "#008B35"
          },
        ]
      }
    ],
  
    "leadSourceMixSeriesyear2023pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  400,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 2500,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 1000,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 1200,
            "color": "#75D284"
          },
          {
            "name": "Event",
            "y": 800,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 600,
            "color": "#008B35"
          },
        ]
      }
    ],

    "leadSourceMixSeriesyear2022pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  601,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 2200,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 1503,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 1803,
            "color": "#75D284"
          },
          {
            "name": "Event",
            "y": 1202,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 902,
            "color": "#008B35"
          },
        ]
      }
    ],


    "conversionyear2023": [
      {
        "name": "Lead",
        "data": [919, 419, 443, 3024,193]
      },
      {
        "name": "Conversion",
        "data": [484, 322, 403, 2700,162]
      }
    ],


    "conversionyear2022": [
      {
        "name": "Achieved",
        "data": [3450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [3000, 5500, 5800, 6200,6500]
      }
    ],

    "conversionyear2021": [
      {
        "name": "Achieved",
        "data": [2450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [2000, 5500, 5800, 6200,6500]
      }
    ],
    
    "programWiseEnrollment": {

      "category":[
        'General Foundation Programme (P)',
        'Bachelor of Science (Hons) in Logistics Management',
        'General Foundation Programme (A)',
        'Bachelor of Engineering (Hons) Computer Engineering (Cyber Security)',
        'Diploma in Logistics Management',
        'Diploma in Computer Science (Computer Science)',
        'Bachelors of Science in Archives and Records Management-I',
        'Bachelor of Engineering(Hons) in Electronics and Telecommunication-N',
        'Bachelor of Engineering (Hons) in Civil Engineering',
        'Bachelor of Science (Hons) in Computer Science (Computer Science)',
      ],

      "data":[
        
        {   
            "name": "Target",
            data: [431, 414,396, 276,272, 261,228, 212,209,197],
            color:"#3142A3",
          
          },
           {
            "name": "Achieved",
            data: [392, 373,354, 244,239, 225,205, 189,187,179],
            color:"#59BFEC",
          },
          
        ]
    },



  },

  "2022-23": {

    "enrollmentsData": [
      {
        "totalenrollments": "6500",
        "increaseby": "3%",
        "achieved": "81%",
        "ministryenrolments": "2500",
        "other":"4500",
        "bachelor": "16",
        "diploma": "16",
        "master": "7",
        "gfp":"2",
        "grossmargin":"Gross Margin",
        "grossmarginValue":"2.82"
      },
    ],

    "series": [
      {
        innerSize: '50%',
        size: '100%',
        "data": [
          {
            "name": "Direct",
            "y":  4000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 2500,
            "color": "#3142A3"
          },
        ]
      }
    ],

    "seriespie": [
      {
        size: '100%',
        "data": [
          {
            "name": "Bachelor",
            "y":  16,
            "color": "#75D284"
          },
          {
            "name": "Diploma",
            "y": 16,
            "color": "#3142A3"
          },
          {
            "name": "Master",
            "y": 7,
            "color": "#EAB600"
          },
          {
            "name": "GFP",
            "y": 2,
            "color": "#008B35"
          },
        ]
      }
    ],
    
    "enrolmentsOverall": [
      {
        "name": "Achieved",
        "data": [4450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [6400, 6600, 6800, 6200,6500]
      }
    ],

    "enrolmentsMinistry": [
      {
        "name": "Achieved",
        "data": [1750, 1936, 2529, 2248,3308]
      },
      {
        "name": "Targeted",
        "data": [2700, 2904, 2701, 3372,2702]
      }
    ],

    "enrolmentsDirect": [
      {
        "name": "Achieved",
        "data": [2700, 2904, 2701, 3372,2702]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],

    "leadSourceMixSeriesyear2024pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  162,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 241,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 404,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 483,
            "color": "#75D284"
          },
          {
            "name": "Event",
            "y": 322,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 241,
            "color": "#008B35"
          },
        ]
      }
    ],
  
    "leadSourceMixSeriesyear2023pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  15000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 15000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 15000,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 15000,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 15000,
            "color": "#008B35"
          },
        ]
      }
    ],

    "leadSourceMixSeriesyear2022pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  10000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 10000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 10000,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 10000,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 10000,
            "color": "#008B35"
          },
        ]
      }
    ],


    "conversionyear2023": [
      {
        "name": "Lead",
        "data": [919, 419, 443, 3024,193]
      },
      {
        "name": "Conversion",
        "data": [484, 322, 403, 2700,162]
      }
    ],


    "conversionyear2022": [
      {
        "name": "Achieved",
        "data": [2280, 1040, 1100, 2800,480]
      },
      {
        "name": "Targeted",
        "data": [1200, 800, 1000, 2500,400]
      }
    ],

    "conversionyear2021": [
      {
        "name": "Achieved",
        "data": [2450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [2000, 5500, 5800, 6200,6500]
      }
    ],
    
    "programWiseEnrollment": {

      "category":[
        'Diploma in Computer Science (Data Analytics)',
        'Master of Science in Cyber Security',
        'Bachelor of Science (Hons) in Logistics Management',
        'B.Eng. (Hons) Computer Engineering with pathway in Cybersecurity',
        'B.Eng. (Hons) Mechanical Engineering',
        'B.Eng. (Hons) Civil Engineering',
        'MSc Fintech',
        'MSc Cyber Security',
        'MSc Electronic Engineering',
        'MSc Construction Project and Cost Management',
      ],

      "data":[
        
        {   
            "name": "Target",
            data: [23, 32,23, 32,23, 32,23, 32,23,12],
            color:"#3142A3",
          
          },
           {
            "name": "Achieved",
            data: [23, 32,23, 32,23, 32,23, 32,24,14],
            color:"#59BFEC",
          },
          
        ]
    },



  },

  "2021-22": {

    "enrollmentsData": [
      {
        "totalenrollments": "8213",
        "increaseby": "10%",
        "achieved": "78%",
        "ministryenrolments": "2200",
        "other":"6013",
        "bachelor": "16",
        "diploma": "16",
        "master": "7",
        "gfp":"2"
      },
    ],

    "series": [
      {
        innerSize: '50%',
        size: '100%',
        "data": [
          {
            "name": "Direct",
            "y":  6013,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 2200,
            "color": "#3142A3"
          },
        ]
      }
    ],

    "seriespie": [
      {
        size: '100%',
        "data": [
          {
            "name": "Bachelor",
            "y":  16,
            "color": "#59BFEC"
          },
          {
            "name": "Diploma",
            "y": 16,
            "color": "#3142A3"
          },
          {
            "name": "Master",
            "y": 7,
            "color": "#657FE6"
          },
          {
            "name": "GFP",
            "y": 2,
            "color": "#008B35"
          },
        ]
      }
    ],
    
    "enrolmentsOverall": [
      {
        "name": "Achieved",
        "data": [4450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],

    "enrolmentsMinistry": [
      {
        "name": "Achieved",
        "data": [1750, 1936, 2529, 2248,3308]
      },
      {
        "name": "Targeted",
        "data": [2700, 2904, 2701, 3372,2702]
      }
    ],
    "enrolmentsDirect": [
      {
        "name": "Achieved",
        "data": [2700, 2904, 2701, 3372,2702]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],

    "leadSourceMixSeriesyear2024pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  601,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 2200,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 1503,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 1803,
            "color": "#75D284"
          },
          {
            "name": "Event",
            "y": 1202,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 902,
            "color": "#008B35"
          },
        ]
      }
    ],
  
    "leadSourceMixSeriesyear2023pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  15000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 15000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 15000,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 15000,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 15000,
            "color": "#008B35"
          },
        ]
      }
    ],

    "leadSourceMixSeriesyear2022pie": [
      {
        "data": [
          {
            "name": "Direct",
            "y":  10000,
            "color": "#59BFEC"
          },
          {
            "name": "Ministry",
            "y": 10000,
            "color": "#657FE6"
          },
          {
            "name": "Referrals",
            "y": 10000,
            "color": "#3142A3"
          },
          {
            "name": "Campaigns",
            "y": 10000,
            "color": "#75D284"
          },
          {
            "name": "Transfers",
            "y": 10000,
            "color": "#008B35"
          },
        ]
      }
    ],


    "conversionyear2023": [
      {
        "name": "Lead",
        "data": [919, 419, 443, 3024,193]
      },
      {
        "name": "Conversion",
        "data": [484, 322, 403, 2700,162]
      }
    ],


    "conversionyear2022": [
      {
        "name": "Achieved",
        "data": [3450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [3000, 5500, 5800, 6200,6500]
      }
    ],

    "conversionyear2021": [
      {
        "name": "Achieved",
        "data": [2450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [2000, 5500, 5800, 6200,6500]
      }
    ],
    
    "programWiseEnrollment": {

      "category":[
        'Diploma in Computer Science (Data Analytics)',
        'Master of Science in Cyber Security',
        'Bachelor of Science (Hons) in Logistics Management',
        'B.Eng. (Hons) Computer Engineering with pathway in Cybersecurity',
        'B.Eng. (Hons) Mechanical Engineering',
        'B.Eng. (Hons) Civil Engineering',
        'MSc Fintech',
        'MSc Cyber Security',
        'MSc Electronic Engineering',
        'MSc Construction Project and Cost Management',
      ],

      "data":[
        
        {   
            "name": "Target",
            data: [23, 32,23, 32,23, 32,23, 32,23,12],
            color:"#3142A3",
          
          },
           {
            "name": "Achieved",
            data: [23, 32,23, 32,23, 32,23, 32,24,14],
            color:"#59BFEC",
          },
          
        ]
    },



  }
}
export { response }
