import React, { useState, useEffect } from 'react';
import '../common/common.css';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Table from '../dashboard/table';
import { response } from '../mapdata/programmesData';
import { useGetProgramListQuery, useEnrollmentsSourceEnrollmentQuery, useCrmQuery } from '../redux/slice/apiSlice';
import { debounce } from 'lodash';
import { formatNumberWithCommas } from '../utils/utils';
import programmes_banner from '../assets/programmes_banner.png'; 
const Programs = () => {
    const [yearChange, setYearChange] = useState('2023-24');
    const [searchString, setSearchString] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const { data: programListData, error: programListDataError, refetch: refetchProgramListData } = useGetProgramListQuery({
        search: searchString,
        page: currentPage
    });
    const { data: crmResponseData, isLoading: crmResponseDataLoading, error: crmResponseEnrollmentError } = useCrmQuery();
    const { data: enrollmentsSourceData, isLoading: enrollmentsSourceDataLoading, error: enrollmentsSourceEnrollmentError } = useEnrollmentsSourceEnrollmentQuery();
    const handleSearchChange = debounce((e) => {
        setSearchString(e.target.value);
        setCurrentPage(1)
    }, 300); // 300ms debounce time
    
    return (
        <div className='programmesWrapper'>
            <section>
                <div className='infoWrapper'>
                    <div className='pageTitle'>
                        <h1>Programmes</h1>
                    </div>
                </div>
            </section>



            <section className='cardInfoWrapper'>
                <ol>
                    {yearChange && (
                        <>
                          <div className="backLink" style={{ backgroundColor: 'white', height: '100%' ,width:'100%'}}>
                        <img
                            src={programmes_banner}
                            alt="Logo"
                            style={{ height: '100%', objectFit: 'fill' }}
                        />
                    </div>
                            {/* <li> */}
                                {/* <div className='cardLeft'>
                                    <p>Running Programmes</p>
                                    <h2>{formatNumberWithCommas(crmResponseData?.data?.program)}</h2>
                                    
                                </div> */}
                                {/* <div className='cardRight'> */}
                                    {/* <div className='chartInfo'> */}
                                        {/* <div className='block'> */}
                                            {/* {enrollmentsSourceData?.data?.pie_chart_data?.map((source, index) => (
                                                <div className='block' key={index}>
                                                    <p>{source.program_name}</p>
                                                    <h2>{formatNumberWithCommas(source.total_enrollment)}</h2>
                                                
                                                </div>
                                            ))} */}
                                        {/* </div> */}
                                    {/* </div> */}

                                    {/* <div className='chartWrapper'>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                credits: { enabled: false },
                                                chart: {
                                                    type: 'pie',
                                                    height: 300,
                                                },
                                                accessibility: {
                                                    point: {
                                                        valueSuffix: '%',
                                                    },
                                                },
                                                tooltip: {
                                                    pointFormat: '<b>{point.y}</b>%',
                                                },
                                                title: {
                                                    text: '',
                                                },
                                                legend: {
                                                    enabled: true,
                                                    floating: false,
                                                    borderWidth: 0,
                                                    align: 'center',
                                                    layout: 'horizontal',
                                                    verticalAlign: 'bottom',
                                                    padding: 3,
                                                },
                                                plotOptions: {
                                                    pie: {
                                                        shadow: false,
                                                    },
                                                    series: {
                                                        allowPointSelect: true,
                                                        showInLegend: true,
                                                        dataLabels: {
                                                            enabled: false,
                                                            format: '{point.name} - {point.y}%',
                                                        },
                                                    },
                                                },
                                                series: response[yearChange].series,
                                            }}
                                        />
                                    </div> */}
                                {/* </div> */}
                            {/* </li> */}
                            <li>
                                <div className='cardLeft'>
                                    <p>Total Students in different Programs</p>
                                    <h2>{formatNumberWithCommas(enrollmentsSourceData?.data?.total_enrollment)}</h2>
                                </div>
                                <div className='cardRight'>
                                    <div className='chartInfo'>
                                        <div className='block'>
                                            {enrollmentsSourceData?.data?.course_data?.map((source, index) => (
                                                <div className='block' key={index}>
                                                    <p>{source.program_name}</p>
                                                    <h2>{formatNumberWithCommas(source.total_enrollment)}</h2>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                    <div className='chartWrapper'>
                                        <HighchartsReact
                                            highcharts={Highcharts}

                                            options={{
                                                credits: { enabled: false },
                                                chart: {
                                                    type: 'pie', // Change 'React Donut Chart' to 'pie'
                                                    height: 300,
                                                },
                                                accessibility: {
                                                    point: {
                                                        valueSuffix: '%',
                                                    },
                                                },
                                                tooltip: {
                                                    pointFormat: '<b>{point.y}</b>',
                                                },
                                                title: {
                                                    text: '',
                                                },
                                                legend: {
                                                    enabled: true,
                                                    floating: false,
                                                    borderWidth: 0,
                                                    align: 'center',
                                                    layout: 'horizontal',
                                                    verticalAlign: 'bottom',
                                                    padding: 3,
                                                },
                                                plotOptions: {
                                                    pie: {
                                                        shadow: false,
                                                        center: ['50%', '50%'],
                                                        innerSize: '50%', // Add innerSize to create a donut effect
                                                    },
                                                    series: {
                                                        allowPointSelect: true,
                                                        showInLegend: true,
                                                        dataLabels: {
                                                            enabled: false,
                                                            format: '{point.name}',
                                                        },
                                                    },
                                                },
                                                series: [{
                                                    name: 'Enrollments',
                                                    colorByPoint: true,
                                                    data: enrollmentsSourceData?.data.course_data.map(item => ({
                                                        name: item.program_name,
                                                        y: parseInt(item.total_enrollment, 10) || 0, // Ensure the value is a number
                                                    })),
                                                }],
                                            }}
                                        />
                                    </div>
                                </div>
                            </li>
                        </>
                    )}
                </ol>
            </section>

            <section className='tableWrapper'>
                <div className='pageTitle'>
                    <h1>All Programmes</h1>
                </div>

                <Table
                    onSearchChange={handleSearchChange}
                    year={yearChange}
                    rows={programListData?.data?.enrollment.data || []}
                    onPageChange={setCurrentPage}
                    total={programListData?.data?.enrollment.total}
                    currentPage={currentPage}
                />
            </section>
        </div>
    );
};

export default Programs;
