// src/components/LoginPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation after login

import { useLoginMutation } from '../redux/slice/authSlice';
import useAuth from '../hooks/useAuth';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [login, { isLoading, isError, error: loginError }] = useLoginMutation(); // Destructure state and error

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Perform the login request
      const result = await login({ email, password }).unwrap(); // `unwrap` for accessing response data
      console.log("result is ", result)

      // Check if login was successful
      if (result.status === 1) {
        // Save token and user info to localStorage on successful login
        localStorage.setItem('token', result.data.token);
        localStorage.setItem('user', JSON.stringify(result.data.user));
        navigate('/'); // Navigate to the dashboard or another page on success
      } else {
        setError('Invalid credentials here'); // Handle any login errors
      }
    } catch (err) {
      // Handle error
      console.log("errr", err)
      setError('Invalid credentials else'); // Show a generic error message
    }
  };

  const isAuthenticated = useAuth('/login');

  return (
    
//     <div className="flex items-center justify-center min-h-screen bg-gray-100 login_page_img">

      
//       <img className='absolute top-10 pr-10' width={'400px'} src="https://d3iw6za7pqdh1y.cloudfront.net/wp-content/uploads/Logo.png" alt="" />
//       <div className="backLink" style={{ backgroundColor: 'black', height: '50px' }}>
//   <img 
//     src="https://mecfuture.mec.edu.om/images/logo/130-bg-1709531140.png" 
//     alt="Logo" 
//     style={{ height: '100%', objectFit: 'fill' , padding: '10px'  }} 
//   />
// </div>
<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 login_page_img">
  {/* MEC Logo */}
  {/* <img
    className="absolute top-10 pr-10"
    width={'400px'}
    src="https://d3iw6za7pqdh1y.cloudfront.net/wp-content/uploads/Logo.png"
    alt="Logo"
  /> */}
   <img
    className="absolute top-10 pr-10"
    width={'400px'}
    src="https://mecfuture.mec.edu.om/images/logo/130-bg-1709531140.png"
    alt="Logo"
    // style={{   marginTop: '80px' }}
  />
  
  {/* Additional Image with Padding */}
  {/* <div className="backLink" style={{ backgroundColor: 'black', height: '50px' }}>
    <img
      src="https://mecfuture.mec.edu.om/images/logo/130-bg-1709531140.png"
      alt="Logo"
      style={{ height: '100%', objectFit: 'fill', padding: '10px' }}
    />
  </div>
       */}
     
     
      <div className="w-full max-w-md p-8 bg-white shadow-md rounded-lg">
        
        <h1 className="text-2xl font-bold mb-6 text-center">Login</h1>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Login
          </button>
          {error && <p className="text-red-500 text-sm text-center">{error}</p>}
        </form>

      </div>
      <div className="flex items-center justify-center bg-gray-100 login_page_img p-4"> {/* Adds padding */}
  {/* Powered By and Logo in One Row */}
  <div className="flex items-center space-x-2 p-4"> {/* Adds inner padding, background color, and rounded corners */}
    <h1 className="font-semibold text-lg text-white">Powered By Singularis</h1> {/* Changes text color to white */}
    {/* <img
      width="200px"
      src="https://d3iw6za7pqdh1y.cloudfront.net/wp-content/uploads/Logo.png"
      alt="Logo"
    /> */}
    
  </div>
</div>




    
    </div>
    
  );
};

export default LoginPage;
