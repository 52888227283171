import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React from 'react';
import { useFinancePage2Query } from '../../../redux/slice/apiSlice';
import { formatNumberWithCommas } from '../../../utils/utils';

const FinancialPage2 = ({ data }) => {
  const { data: financeResponse, error: financeError } = useFinancePage2Query();

  const finance = financeResponse?.data;
  // Placeholder data for tables
  const sourcesData = finance?.source_entry_count?.map((data) => {
    return { 'source': data?.source_code, 'count': data?.entry_no_count }
  })


  const financialSummary = finance?.source_creadit_debit_amount.map(source => ({
    sources: source?.source_type?.toString() ?? "other",  // Convert source_type to string, default to "other"
    countEntry: source?.entry_no_count ?? 0,  // Entry count
    debitNum: source?.debit_amount_count ?? 0,  // Debit count
    debitAmount: parseFloat(source?.total_debit_amount) ?? 0,  // Convert total debit amount to number
    creditNum: source?.credit_amount_count ?? 0,  // Credit count
    creditAmount: parseFloat(source?.total_credit_amount) ?? 0,  // Convert total credit amount to number
  }));

  let minValue = 0, maxValue = 0;

  const pieChartData = finance?.source_type_amount?.map(summary => {
    minValue = Math.min(minValue, summary?.total_amounts ?? 0);
    maxValue = Math.max(maxValue, summary?.total_amounts ?? 0);
    return {
      name: summary.source_type ?? "other",
      y: summary.total_amounts,
    };
  });


  const totalEntries = finance?.source_entry_count?.reduce((total, item) => total + item.entry_no_count, 0);

  return (
    <div className="">

      <div className="mb-4 bg-white p-2 my-4">
      <h3 className="text-xl font-semibold mb-4">{formatNumberWithCommas(finance?.source_entry_count?.length)} Sources</h3>
        <div className="grid grid-cols-2 gap-4">
          {/* Sources Table */}
          <div className='bg-white p-2 rounded-sm'>
            <table className="w-full table-auto border-collapse border border-gray-400">
              <thead>
                <tr className="bg-blue-100">
                  <th className="border p-2">Sources</th>
                  <th className="border p-2">Count of Entry No_</th>
                </tr>
              </thead>
              <tbody>
                {sourcesData?.map((item, index) => (
                  <tr key={index}>
                    <td className="border p-2">{item.source}</td>
                    <td className="border p-2">{item.count}</td>
                  </tr>
                ))}
                <tr className="bg-blue-100">
                  <td className="border p-2 font-semibold">Grand Total</td>
                  <td className="border p-2 font-semibold">{totalEntries}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Pie Chart */}
          <div className='bg-white p-2 rounded-sm'>
            <h4 className="font-semibold mb-4">Sources and Total Amount</h4>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: { type: 'column' },
                title: { text: '' },
                xAxis: {
                  categories: finance?.source_type_amount?.map(data => data?.source_type ?? "other"),  // Dynamic categories from the finance array
                  title: { text: 'Sources and Total Amount' },
                },
                yAxis: { min: minValue, max: maxValue, title: { text: 'Amount' } },
                series: [{
                  name: 'Amount',
                  data: pieChartData,  // Dynamic data from the finance array
                }],
              }}
            />

          </div>
        </div>
      </div>

      {/* Financial Summary Table */}
     
      <div className='my-[20px] bg-white p-2'>
      <h3 className="text-xl font-semibold mb-4">Debit and Credit Details</h3>
        <table className="w-full table-auto border-collapse border border-gray-400">
          <thead>
            <tr className="bg-blue-100">
              <th className="border p-2">Sources</th>
              <th className="border p-2">Count of Entry No_</th>
              <th className="border p-2">Number of Debit</th>
              <th className="border p-2">Debit Amount</th>
              <th className="border p-2">Number of Credit</th>
              <th className="border p-2">Credit Amount</th>
            </tr>
          </thead>
          <tbody>
            {financialSummary?.map((item, index) => (
              <tr key={index}>
                <td className="border p-2">{item.sources}</td>
                <td className="border p-2">{item.countEntry}</td>
                <td className="border p-2">{item.debitNum}</td>
                <td className="border p-2">{item.debitAmount.toLocaleString()}</td>
                <td className="border p-2">{item.creditNum}</td>
                <td className="border p-2">{item.creditAmount.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

<div className="bg-white p-2">
<h1 className='font-bold text-xl'>GLA with Transactions</h1>
      <div className="grid grid-cols-2 gap-4 my-2">
        {/* Sources Table */}
        <div className='bg-white p-2 rounded-sm'>
          <table className="w-full table-auto border-collapse border border-gray-400">
            <thead>


              <tr className="bg-blue-100">
                <th className="border p-2">User ID</th>
                <th className="border p-2"># GLA Accounts Doc Type 0</th>
                <th className="border p-2"># GLA Accounts Doc Type 1</th>
                <th className="border p-2"># GLA Accounts Doc Type 3</th>
                <th className="border p-2">Grand Total</th>
              </tr>
            </thead>
            <tbody>
              {finance?.user_source_graph?.map((item, index) => (
                <tr key={index}>
                  <td className="border p-2">{item.user_id}</td>
                  <td className="border p-2">{item?.source_user_count['0']}</td>
                  <td className="border p-2">{item?.source_user_count['1']}</td>
                  <td className="border p-2">{item?.source_user_count['3']}</td>
                  <td className="border p-2">{item.total_user}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>

        {/* Pie Chart */}
        <div className='bg-white p-2 rounded-sm'>
        

          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: 'bar',
              },
              title: {
                text: '',
              },
              xAxis: {
                categories: finance?.user_source_graph.map((data) => data?.user_id),  // User IDs as categories
              },
              yAxis: {
                min: 0,
                title: {
                  text: '',
                },
              },
              legend: {
                reversed: true,
              },
              plotOptions: {
                series: {
                  stacking: 'normal',
                  dataLabels: {
                    enabled: true,
                  },
                },
              },
              series: [
                {
                  name: '0',
                  data: finance?.user_source_graph.map((data) => data?.source_user_count['0'] ?? 0),  // Data for '0' source type
                },
                {
                  name: '1',
                  data: finance?.user_source_graph.map((data) => data?.source_user_count['1'] ?? 0),  // Data for '1' source type
                },
                {
                  name: '3',
                  data: finance?.user_source_graph.map((data) => data?.source_user_count['3'] ?? 0),  // Data for '3' source type
                },
                {
                  name: 'other',
                  data: finance?.user_source_graph.map((data) => data?.source_user_count[''] ?? 0),  // Data for 'other' source type
                },
              ],
            }}
          />

          {/* <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: { type: 'column' },
              title: { text: '' },
              xAxis: {
                categories: finance?.source_type_amount?.map(data => data?.source_type ?? "other"),  // Dynamic categories from the finance array
                title: { text: 'GLA Transactions' },
              },
              yAxis: { min: minValue, max: maxValue, title: { text: 'Amount' } },
              series: [{
                name: 'Amount',
                data: pieChartData,  // Dynamic data from the finance array
              }],
            }}
          /> */}

        </div>
      </div>


    </div>
</div>
  );
};

export default FinancialPage2;
