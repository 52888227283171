import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts'
const TrendingReels = (props) => {
  const { community } = props;
  return (<div>

    <HighchartsReact
      highcharts={Highcharts}
      options={{
        title: {
          text: 'Top 5 Trending Reels',  // Adjusted title for your top post data
        },
        xAxis: {
          categories: community?.top_reels?.map((data) => data?.description.substring(0, 20)),
        },
        yAxis: {
          title: {
            text: 'Count',
          },
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
        },
        series: [
          {
            name: 'Likes',
            data: community?.top_reels?.map((data) => data?.like_count),
            type: 'column',
          },
          {
            name: 'Comments',
            data: community?.top_reels?.map((data) => data?.comment_count),
            type: 'column',
          },
          {
            name: 'Views',
            data: community?.top_reels?.map((data) => data?.view_count),
            type: 'column',
          },
        ],
      }}
    />
  </div>)

}

export default TrendingReels