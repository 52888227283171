import React, { useState } from 'react';
import '../common/common.css';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { response } from '../mapdata/year2';
import { useGetAddmissionQuery } from '../redux/slice/apiSlice';

const AdmissionDashboard = () => {
    const [yearChanges, setYearChanges] = useState('');
    const [year2023, setYear2023] = useState('year2023');
    const [year2022, setYear2022] = useState('');
    const [year2021, setYear2021] = useState('');
    const { data: admissionData, isLoading: admissionDataLoading, error: admissionEnrollmentError } = useGetAddmissionQuery();


    const YearChange = (e) => {
        const value = e.target.value;
        console.log(value);
        setYearChanges(value);

        if (value === 'year2023') {
            setYear2023(value);
            setYear2021('');
            setYear2022('');
        } else if (value === 'year2022') {
            setYear2022(value);
            setYear2023('');
            setYear2021('');
        } else if (value === 'year2021') {
            setYear2021(value);
            setYear2022('');
            setYear2023('');
        }
    };

    const semesterData = admissionData?.data.semester_student.map(item => ({
        name: item.University,
        data: Object.entries(item.user_count).map(([key, value]) => ({
            name: key,
            y: value,
        })),
    }));

    const admissionDataForYear = admissionData?.data.university_admission_count.map(item => ({
        name: item.University,
        y: item.user_count,
    }));

    const courseData = admissionData?.data?.course_session.map(item => ({
        name: item?.Certification,
        data: Object.entries(item?.user_count).map(([key, value]) => ({
            name: key,
            y: value,
        })),
    }));

    const sponsorData = admissionData?.data?.sponsors.map(item => ({
        name: item.University,
        data: Object.entries(item.user_count).map(([key, value]) => ({
            name: key,
            y: value,
        })),
    }));



    return (
        <div className='revenueWrapper'>
            <h1 className='font-bold text-2xl mb-4'>Admission</h1>


            <section className='chartWrapper'>
                <div className='chartLeft'>
                    <div className='chartheading'>
                        <h3>Admission By University</h3>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={{
                        chart: {
                            type: 'bar'
                        },
                        title: {
                            text: 'Ferry passengers by vehicle type 2024'
                        },
                        xAxis: {
                            categories: admissionData?.data?.semester_student.map((data) => data?.University)
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: ''
                            }
                        },
                        legend: {
                            reversed: true
                        },
                        plotOptions: {
                            series: {
                                stacking: 'normal',
                                dataLabels: {
                                    enabled: true
                                }
                            }
                        },

                        series: [{
                            name: '2023 - Fall',
                            data: admissionData?.data?.semester_student.map((data) => data?.user_count['2023 - Fall'])
                        }, {
                            name: '2024 - Spring',
                            data: admissionData?.data?.semester_student.map((data) => data?.user_count['2024 - Spring'])
                        }, {
                            name: '2024 - Summer',
                            data: admissionData?.data?.semester_student.map((data) => data?.user_count['2024 - Summer'])
                        },
                        
                    ]
                    }} />
                </div>
                <div className='chartRight'>
                    <div className='chartheading'>
                        <h3>Admission</h3>
                    </div>


                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'pie',
                                height: 400,
                            },
                            accessibility: {
                                point: {
                                    valueSuffix: '%'
                                }
                            },
                            tooltip: {
                                pointFormat: '<b>{point.y}%</b>'
                            },
                            title: {
                                text: ''
                            },
                            legend: {
                                enabled: true,
                                floating: false,
                                borderWidth: 0,
                                align: 'center',
                                layout: 'horizontal',
                                verticalAlign: 'bottom',
                                padding: 3,
                            },
                            plotOptions: {
                                pie: {
                                    shadow: false,
                                    center: ['50%', '50%'],
                                },
                                series: {
                                    allowPointSelect: true,
                                    showInLegend: true,
                                    dataLabels: {
                                        enabled: false,
                                        format: '{point.name}',
                                    },
                                }
                            },
                            series: [{
                                size: '100%',
                                data: admissionData?.data?.university_admission_count.map(data => ({
                                    name: data.University,
                                    y: Number(data.user_count), // Convert total to number
                                })) || [],
                            }]
                        }}
                    />



                </div>
            </section>

            <section className='chartWrapper'>
                <div className='chartLeft'>
                    <div className='chartheading'>
                        <div className='block'>
                            <h3>  Sponsors</h3>
                            <a href='#'>View all <i className='fa fa-chevron-right'></i></a>
                        </div>
                    </div>
                    <div className='chatWrapper'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: {
                                    type: 'column'
                                },
                                title: {
                                    text: ''
                                },
                                subtitle: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: admissionData?.data?.sponsors.map((data) => data?.University),
                                    title: {
                                        text: 'Sponsorship Type'
                                    }
                                },
                                yAxis: {
                                    min: 0,
                                    title: {
                                        text: 'Number of Students'
                                    }
                                },
                                tooltip: {
                                    pointFormat: '{series.name}: <b>{point.y}</b>'
                                },
                                plotOptions: {
                                    column: {
                                        pointPadding: 0.2,
                                        borderWidth: 0
                                    }
                                },
                                series: Object.keys(admissionData?.data?.sponsors[0].user_count || {}).map((semester) => {
                                    return {
                                        name: semester, // The key (e.g. "2023 - Fall", "2024 - Spring")
                                        data: admissionData?.data?.sponsors.map((sponsor) => sponsor.user_count[semester]) // Extract the counts for each sponsor for that semester
                                    };
                                })


                                // series: [{
                                //     name: 'MEC',
                                //     data: admissionData?.data?.sponsors.map((data) => data?.user_count.MEC)
                                // }, {
                                //     name: 'MOHE',
                                //     data: admissionData?.data?.sponsors.map((data) => data?.user_count.MOHE)
                                // }]
                            }}
                        />
                    </div>
                </div>

                <div className='chartRight'>
                    <div className='chartheading'>
                        <div className='block'>
                            <h3>  Courses</h3>

                        </div>
                    </div>
                    <div className='chatWrapper'>
                        <HighchartsReact highcharts={Highcharts} options={{
                            chart: {
                                type: 'bar'
                            },
                            title: {
                                text: ''
                            },
                            xAxis: {
                                categories: admissionData?.data?.course_session.map((data) => data?.University)
                            },
                            yAxis: {
                                min: 0,
                                title: {
                                    text: ''
                                }
                            },
                            legend: {
                                reversed: true
                            },
                            plotOptions: {
                                series: {
                                    stacking: 'normal',
                                    dataLabels: {
                                        enabled: true
                                    }
                                }
                            },

                            series: [{
                                name: '2023 - Fall',
                                data: admissionData?.data?.course_session.map((data) => data?.user_count['2023 - Fall'])
                            }, {
                                name: '2024 - Spring',
                                data: admissionData?.data?.course_session.map((data) => data?.user_count['2024 - Spring'])
                            }, {
                                name: '2024 - Summer',
                                data: admissionData?.data?.course_session.map((data) => data?.user_count['2024 - Summer'])
                            }]
                        }} />
                    </div>
                </div>


            </section>

            <section className='chartWrapper'>

            </section>
        </div>
    );
};

export default AdmissionDashboard;
