const response = {
  "2023-24": {
    "enrolmentsOverall": [
      {
        "name": "Achieved",
        "data": [4450, 4840, 5230, 5620,6010]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],
    "enrolmentsMinistry": [
      {
        "name": "Achieved",
        "data": [1750, 1936, 2529, 2248,3308]
      },
      {
        "name": "Targeted",
        "data": [2700, 2904, 2701, 3372,2702]
      }
    ],
    "enrolmentsDirect": [
      {
        "name": "Achieved",
        "data": [2700, 2904, 2701, 3372,2702]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200,6500]
      }
    ],
    "series": [
      {
        "name": "2023-24",
        "colorByPoint": false,
        "total": "9.7m",
        "data": [
          {
            "name": "Fee Income",
            "y":  8.60,
            "color": "#59BFEC"
          },
          {
            "name": "Hostel Income",
            "y": 1.25,
            "color": "#657FE6"
          },
          {
            "name": "Hostel School Rental",
            "y": 0.06,
            "color": "#3142A3"
          },
          {
            "name": "Rental & Misc Income",
            "y": 0.20,
            "color": "#00BC4B"
          },
          {
            "name": "Training & Consultancy",
            "y": 0.60            ,
            "color": "#008B35"
          },
          
        ]
      }
    ],
    "studentsatisfaction": [
      {
        "name": "Programme Satisfaction",
        "progress": 25
      },
      {
        "name": "Employability Support",
        "progress": 15
      },
      {
        "name": "Teaching Quality",
        "progress": 35
      },
      {
        "name": "Student Experience",
        "progress": 75
      }
    ],
    "employment": [
      {
        "name": "Achieved",
        "data": [4450, 4840, 5240, 5620]
      },
      {
        "name": "Targeted",
        "data": [5000, 5500, 5800, 6200]
      }
    ],
    "revenuecardData": [
      {
        "icon": "payments",
        "title": "Projected Revenue",
        "total": "9.7m",
        "progress": 97,
        "target": "10m",
        "ebitatitle": "EBITA",
        "hostelTitle":'Hostel',
        "feeIncomeTitle":'Fee Income',
        "pattitle": "Pat",
        "ebita": "2.82m",
        "pat": "--",
        "hostelValue":'0.85m',
        "feeIncomeValue":'8.60m',
        "link":'/revenue',
        "grossmargin":"G.Margin",
        "grossmarginValue":"2.82m"
        
      },
      {
        "icon": "school",
        "title": "Enrolments",
        "total": "4450",
        "progress": 89,
        "target": "5000",
        "ebitatitle": "Ministry",
        "hostelTitle":'Direct',
        "feeIncomeTitle":'',
        "pattitle": "Pat",
        "ebita": "1750",
        "pat": "1.29m",
        "hostelValue":'2700',
        "feeIncomeValue":'',
        "link":'/enrollments'
      },
      {
        "icon": "book_2",
        "title": "Programmes",
        "total": "103",
        "ebitatitle": "Diploma",
        "hostelTitle":'Graduate',
        "feeIncomeTitle":'GFP',
        "pattitle": "GFP",
        "postTitle": "Master",
        "ebita": "39",
        "pat": "24",
        "post": "13",
        "otherText": "in 15 Departments",
        "hostelValue":'48',
        "feeIncomeValue":'3',
        "link":'/programmes'
      },
      {
        "icon": "work",
        "title": "Employability",
        "total": "850",
        "progress": 85,
        "target": "1000",
        "ebitatitle": "Jobs",
        "hostelTitle":'Internship',
        "feeIncomeTitle":'',
        "pattitle": "Internships",
        "postTitle": "Corporate Partnership",
        "ebita": "75",
        "pat": "30%",
        "post": "170",
        "hostelValue":'200',
        "feeIncomeValue":'3',
        "link":'/employability',
      }
    ]
  },
  "2022-23": {
    "enrolmentsOverall": [
      {
        "name": "Achieved",
        "data": [2.5, 5.1, 10.7, 6.3]
      },
      {
        "name": "Targeted",
        "data": [11.8, 1.5, 11.2, 5.9]
      }
    ],
    "enrolmentsMinistry": [
      {
        "name": "Achieved",
        "data": [14.7, 7.3, 10.0, 4.9]
      },
      {
        "name": "Targeted",
        "data": [1.5, 0.9, 6.2, 1.7]
      }
    ],
    "enrolmentsDirect": [
      {
        "name": "Achieved",
        "data": [6.5, 7.0, 9.9, 15.1]
      },
      {
        "name": "Targeted",
        "data": [-0.4, 1.0, 6.1, 12.3]
      }
    ],
    "series": [
      {
        "name": "2022-23",
        "colorByPoint": false,
        "total": "8.47m",
        "data": [
          {
            "name": "Fee Income",
            "y": 7.86,
            "color": "#59BFEC"
          },
          {
            "name": "Hostel income",
            "y": 0.33,
            "color": "#657FE6"
          },
          {
            "name": "Hostel School Rental",
            "y": 0,
            "color": "#3142A3"
          },
          {
            "name": "Rental & Misc Income",
            "y": 0.07,
            "color": "#00BC4B"
          },
          {
            "name": "Training & Consultancy",
            "y": 0.20,
            "color": "#008B35"
          },
          
        ]
      }
    ],
    "studentsatisfaction": [
      {
        "name": "Programme Satisfaction",
        "progress": 22
      },
      {
        "name": "Employability Support",
        "progress": 12
      },
      {
        "name": "Teaching Quality",
        "progress": 42
      },
      {
        "name": "Student Experience",
        "progress": 82
      }
    ],
    "employment": [
      {
        "name": "Achieved",
        "data": [7.3, 1.6, 9.2, 14.2]
      },
      {
        "name": "Targeted",
        "data": [1.4, 0.9, 5.8, 11.6]
      }
    ],
    "revenuecardData": [
      {
        "icon": "payments",
        "title": "Revenue",
        "total": "8.47m",
        "progress": 73,
        "target": "10.4m",
        "ebitatitle": "EBITA",
        "hostelTitle":'Hostel',
        "feeIncomeTitle":'Fee Income',
        "pattitle": "Pat",
        "ebita": "2.25m",
        "pat": "0.89m",
        "hostelValue":'0.33m',
        "feeIncomeValue":'7.86m',
        "link":'/revenue',
        "grossmargin":"G.Margin",
        "grossmarginValue":"2.25m"
        
      },
      {
        "icon": "school",
        "title": "Enrolments",
        "total": "4450",
        "progress": 78,
        "target": "5700",
        "ebitatitle": "Ministry",
        "hostelTitle":'Direct',
        "feeIncomeTitle":'',
        "pattitle": "Pat",
        "ebita": "1750",
        "pat": "1.29m",
        "hostelValue":'2700',
        "feeIncomeValue":'',
        "link":'/enrollments'
      },
      {
        "icon": "book_2",
        "title": "Programmes",
        "total": "83",
        "ebitatitle": "Diploma",
        "hostelTitle":'Graduate',
        "feeIncomeTitle":'GFP',
        "pattitle": "GFP",
        "postTitle": "Master",
        "ebita": "39",
        "pat": "24",
        "post": "13",
        "otherText": "in 15 Departments",
        "hostelValue":'48',
        "feeIncomeValue":'3',
        "link":''
      },
      {
        "icon": "work",
        "title": "Employability",
        "total": "550",
        "progress": 85,
        "target": "1000",
        "ebitatitle": "Jobs",
        "hostelTitle":'Internship',
        "feeIncomeTitle":'',
        "pattitle": "Internships",
        "postTitle": "Corporate Partnership",
        "ebita": "75",
        "pat": "30%",
        "post": "160",
        "hostelValue":'200',
        "feeIncomeValue":'3',
        "link":''
      }
    ]
  },
  "2021-22": {
    "enrolmentsOverall": [
      {
        "name": "Achieved",
        "data": [6.2, 6.7, 9.2, 37.9]
      },
      {
        "name": "Targeted",
        "data": [0.8, 0.4, 5.7, 9.8]
      }
    ],
    "enrolmentsMinistry": [
      {
        "name": "Achieved",
        "data": [1.3, 7.1, 8.9, 4.8]
      },
      {
        "name": "Targeted",
        "data": [0.3, 1.0, 5.6, 1.5]
      }
    ],
    "enrolmentsDirect": [
      {
        "name": "Achieved",
        "data": [2.8, 6.5, 10.1, 8.0]
      },
      {
        "name": "Targeted",
        "data": [0.4, 0.3, 1.5, 8.9]
      }
    ],
    "series": [
      {
        "name": "2021-22",
        "colorByPoint": false,
        "total": "7.9m",
        "data": [
          {
            "name": "Fee Income",
            "y": 7.45,
            "color": "#59BFEC"
          },
          {
            "name": "Hostel income",
            "y": 0.32,
            "color": "#657FE6"
          },
          {
            "name": "Hostel School Rental",
            "y": 0,
            "color": "#3142A3"
          },
          {
            "name": "Rental & Misc Income",
            "y": 0.12,
            "color": "#00BC4B"
          },
          {
            "name": "Training & Consultancy",
            "y": 0,
            "color": "#008B35"
          },
          
        ]
      }
    ],
    "studentsatisfaction": [
      {
        "name": "Programme Satisfaction",
        "progress": 12
      },
      {
        "name": "Employability Support",
        "progress": 7
      },
      {
        "name": "Teaching Quality",
        "progress": 24
      },
      {
        "name": "Student Experience",
        "progress": 55
      }
    ],
    "employment": [
      {
        "name": "Achieved",
        "data": [4.5, 6.8, 9.2, 10.8]
      },
      {
        "name": "Targeted",
        "data": [1.1, 0.7, 5.9, 11.0]
      }
    ],
    "revenuecardData": [
      {
        "icon": "payments",
        "title": "Revenue",
        "total": "8.10m",
        "progress": 102,
        "target": "7.9m",
        "ebitatitle": "EBITA",
        "hostelTitle":'Hostel',
        "feeIncomeTitle":'Fee Income',
        "pattitle": "Pat",
        "ebita": "1.78m",
        "pat": "0.38m",
        "hostelValue":'0.34m',
        "feeIncomeValue":'7.66m',
        "link":'/revenue',
        "grossmargin":"G.Margin",
        "grossmarginValue":"1.78m"
        
      },
      {
        "icon": "school",
        "title": "Enrolments",
        "total": "4780",
        "progress": 79,
        "target": "6000",
        "ebitatitle": "Ministry",
        "hostelTitle":'Direct',
        "feeIncomeTitle":'',
        "pattitle": "Pat",
        "ebita": "1750",
        "pat": "1.29m",
        "hostelValue":'2700',
        "feeIncomeValue":'',
        "link":'/enrollments'
      },
      {
        "icon": "book_2",
        "title": "Programmes",
        "total": "83",
        "ebitatitle": "Diploma",
        "hostelTitle":'Graduate',
        "feeIncomeTitle":'GFP',
        "pattitle": "GFP",
        "postTitle": "Master",
        "ebita": "39",
        "pat": "24",
        "post": "13",
        "otherText": "in 15 Departments",
        "hostelValue":'48',
        "feeIncomeValue":'3',
        "link":''
      },
      {
        "icon": "work",
        "title": "Employability",
        "total": "150",
        "progress": 55,
        "target": "1000",
        "ebitatitle": "Jobs",
        "hostelTitle":'Internship',
        "feeIncomeTitle":'',
        "pattitle": "Internships",
        "postTitle": "Corporate Partnership",
        "ebita": "75",
        "pat": "30%",
        "post": "160",
        "hostelValue":'200',
        "feeIncomeValue":'3',
        "link":''
      }
    ]
  }
}

export { response }
