const response = {

  "revenueData": [
    {
      "total": "9.7m",
      "target": "10.18m",
      "increaseby": "5%"
    },
  ],

  "revenueGrowthRate": [
    {
      "name": "Achieved",
      "data": [9.70, 8.47, 8.22, 8.47,8.10]
    },
   
  ],

  "ebitapat": [
    {
      "name": "EBITA(in million)",
      "data": [1.78, 2.25, 2.82],
      "color" : '#3142A3'
    },
    {
      "name": "PAT(in million)",
      "data": [0.38, 0.89, 1.07],
      "color" : '#59BFEC'
    }
  ],


  "costratio": [
    {
      "name": "HR cost to revenue ratio",
      "data": [0.49, 0.47, 0.47, 0.46,0.42],
      "color" : '#008B35'
    },
    {
      "name": "Direct expenses to revenue ratio",
      "data": [0.47, 0.46, 0.46, 0.44,0.41],
      "color" : '#00BC4B'
    },
   
    {
      "name": "General and administrative expenses to revenue ratio",
      "data": [0.22, 0.21, 0.21, 0.21,0.11],
      "color" : '#657FE6'
    },
   
  ],


  "programWiseRevenue": {
      "category":[
        'Diploma in Computer Science (Data Analytics)',
        'Master of Science in Cyber Security',
        'Bachelor of Science (Hons) in Logistics Management',
        'B.Eng. (Hons) Computer Engineering with pathway in Cybersecurity',
        'B.Eng. (Hons) Mechanical Engineering',
        'B.Eng. (Hons) Civil Engineering',
        'MSc Fintech',
        'MSc Cyber Security',
        'MSc Electronic Engineering',
        'MSc Construction Project and Cost Management',
      ],
      "data":[
        
        {   
            "name": "Budget",
            data: [23, 32, 6, 20,3, 2, 6, 3,3, 2, 6],
            color:"#59BFEC",
          
          },
           {
            "name": "Actual Expenses",
            data: [43, 24, 16, 11,3, 2, 6, 3,3, 2, 6],
            color:"#FF7B17",
          },
          {   
            "name": "Target Revenue",
            data: [44, 22, 66, 20,3, 2, 8, 16,3, 2, 6],
            color:"#657FE6"
          },
          {
            "name": "Achieved Revenue",
            data: [63, 37, 36, 3,16, 2, 6, 3,3, 10, 6],
            color:"#EAB600"
          },
          {   
            "name": "Planned Profitability",
            data: [23, 2, 56, 20,3, 2, 6, 9,3, 2, 6],
            color:"#3142A3"
        },
        {
          "name": "ActualProfitability",
          data: [39, 42, 96, 33,53, 22, 6, 43,31, 24, 61],
          color:'#008B35'
        },
        ]
    },


    "year2023": {
      "series": [
        {
          "data": [
            {
              "name": "Fee Income",
              "y":  8.6,
              "color": "#59BFEC"
            },
            {
              "name": "Hostel income",
              "y": 0.25,
              "color": "#657FE6"
            },
            {
              "name": "Hostel School Rental",
              "y": 0.055,
              "color": "#3142A3"
            },
            {
              "name": "Rental & Misc Income",
              "y": 0.2,
              "color": "#00BC4B"
            },
            {
              "name": "Training & Consultancy",
              "y": 0.3            ,
              "color": "#008B35"
            },
            {
              "name": "TeachOut Revenue",
              "y": 0.3              ,
              "color": "#75D284"
            }
          ]
        }
      ],
    
    },

  "year2022": {
    "series": [
      {
        
        "data": [
          {
            "name": "Fee Income",
            "y":  7.5,
            "color": "#FF9A3E"
          },
          {
            "name": "Hostel income",
            "y": 0.35,
            "color": "#657FE6"
          },
          {
            "name": "Hostel School Rental",
            "y": 0.06,
            "color": "#3142A3"
          },
          {
            "name": "Rental & Misc Income",
            "y": 0.35,
            "color": "#00BC4B"
          },
          {
            "name": "Training & Consultancy",
            "y": 0.32            ,
            "color": "#008B35"
          },
          {
            "name": "TeachOut Revenue",
            "y": 0.3              ,
            "color": "#75D284"
          }
        ]
      }
    ],
  },

 "year2021": {
    "series": [
      {
        "data": [
          {
            "name": "Fee Income",
            "y":  7,
            "color": "#EAB600"
          },
          {
            "name": "Hostel income",
            "y": 0.4,
            "color": "#657FE6"
          },
          {
            "name": "Hostel School Rental",
            "y": 0.2,
            "color": "#3142A3"
          },
          {
            "name": "Rental & Misc Income",
            "y": 0.35,
            "color": "#00BC4B"
          },
          {
            "name": "Training & Consultancy",
            "y": 0.32            ,
            "color": "#008B35"
          },
          {
            "name": "TeachOut Revenue",
            "y": 0.3              ,
            "color": "#75D284"
          }
        ]
      }
    ],
  }





}

export { response }
