import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React from 'react';

const TopContributor = (props) => {
    const { data, title } = props;

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'column',
                },
                credits: { enabled: false },
                title: {
                    text:title,  // Empty title as per your original code
                },
                xAxis: {
                    categories: data?.map((data) => data?.name) || [],
                },
                yAxis: {
                    title: {
                        text: "Count",
                    },
                },
                series: [{

                    colorByPoint: true,
                    data: data?.map(item => ({
                        name: item?.name || 'Unknown',  // Fallback for item.name
                        y: Number(item?.post_count) || 0,  // Using `Number` to ensure proper number conversion
                    })) || [],  // Fallback for the data array
                }],
            }}
        />
    )
}

export default TopContributor