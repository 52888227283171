import React, { useState, useEffect } from 'react'

const AddUserModal = ({ closeModal, user }) => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [commorates, setCommorates] = useState('');
  const [department, setDepartment] = useState('');
  const [designation, setDesignation] = useState('');

  const departments = [
    { id: 83, name: 'Finance' },
    { id: 84, name: 'Admission' },
    { id: 85, name: 'Library' },
    { id: 86, name: 'Sales' },
    { id: 88, name: 'Mechanical' },
    { id: 89, name: 'Electrical' },
    { id: 90, name: 'Computer Science' },
    { id: 91, name: 'Electronics Communication' },
    { id: 92, name: 'Administration' },
    { id: 117, name: 'Alumni' },
    { id: 118, name: 'Records Management & Archival Studies' },
    { id: 119, name: 'Management Studies' },
    { id: 120, name: 'Centre for Foundation Studies' },
    { id: 121, name: 'Mathematics & Applied Sciences' },
    { id: 122, name: 'Civil & Mechanical Engineering' },
    { id: 123, name: 'Centre for Academic Writing' },
  ];


  const designationList = [
    { id: 100, name: 'Professor' },
    { id: 101, name: 'Student' },
    { id: 102, name: 'Admin Staff' },
    { id: 103, name: 'Librarian' },
    { id: 104, name: 'Accountant' },
    { id: 105, name: 'Assistant Professor' },
    { id: 106, name: 'Associate Professor' },
    { id: 107, name: 'Reade' },
  ];



  useEffect(() => {
    if (user) {
      setName(user.user_name || '');
      setEmail(user.email || '');
      setRole(user.role || '');

      setDepartment(departments.filter((data) => data.id == user.department_name).first)
      // setDesignation(designationList.filter((data) => data.id == user.department_name).first)
      // ... (set other fields)
    }
  }, [user]);

  const handleSignUp = () => {
    // Handle the user creation logic here
    console.log({
      name,
      email,
      password,
      role,
      mobileNo,
      address,
      city,
      department,
      designation,
    });
    // Close the modal after successful creation
    closeModal();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-lg">
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="off"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="off"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            autoComplete="off"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Role</option>
            <option value="Presenter">Presenter</option>
            <option value="Learner">Learner</option>
            <option value="Staff">Staff</option>
          </select>
          <input
            type="text"
            placeholder="Mobile Number"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            autoComplete="off"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />
          <input
            type="text"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            autoComplete="off"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />
          <input
            type="text"
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            autoComplete="off"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />
          {/* <input
            type="text"
            placeholder="Commorates"
            value={commorates}
            onChange={(e) => setCommorates(e.target.value)}
            autoComplete="off"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          /> */}
          <select
            value={department}
            onChange={(e) => {
              setDepartment(e.target.value)
            }}
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Department</option>
            {departments.map(dept => (
              <option key={dept.id} value={dept.id}>
                {dept.name}
              </option>
            ))}
          </select>

          <select
            value={designation}
            onChange={(e) => {
              setDesignation(e.target.value)
            }}
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Designation</option>
            {designationList.map(dept => (
              <option key={dept.id} value={dept.id}>
                {dept.name}
              </option>
            ))}
          </select>
          {/* <input
            type="text"
            placeholder="Designation"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
            autoComplete="off"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          /> */}
          <button
            onClick={handleSignUp}
            className="w-full py-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
          >
            {
              user ? 'Update' : ' Create'
            }
          </button>
          <button
            onClick={closeModal}
            className="w-full py-2 mt-2 bg-gray-300 text-black rounded hover:bg-gray-400 transition duration-200"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddUserModal;
