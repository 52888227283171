import React, { lazy, Suspense } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useGetStudentCourseQuery } from '../redux/slice/apiSlice';
import './Table.css'
import { formatNumberWithCommas } from '../utils/utils';
// Lazy load components
const SemesterWiseClass = lazy(() => import('./SemesterWiseClass'));
const Top2SponsorsChart = lazy(() => import('./Top2SponsorsChart'));
const AdmissionDashboard = lazy(() => import('./Admission'));
const StudentsSummaryChart = lazy(() => import('./StudentSummary'));

const StudentCourse = () => {
    const { data: studentCourseData, error: studentCourseError } = useGetStudentCourseQuery()
    const enrollmentsSourceData = {
        data: {
            pie_chart_data: [
                { enrollment_source: 'Masnews', total_enrollment: '2' },
                { enrollment_source: 'GFP-Fnd', total_enrollment: '2007' },
                { enrollment_source: 'Diploma', total_enrollment: '2523' },
                { enrollment_source: 'Bachelor', total_enrollment: '5413' },
            ],
        },
    };

    const data = studentCourseData?.data;
    return (
        <div className="container mx-auto p-4">
            <h1 className='font-bold text-2xl mb-4'>Student Dashboard</h1>
            <div className="grid grid-cols-6 gap-4">
                <div className="bg-blue-500 text-white rounded-md p-4 text-center">
                    <h2 className="text-2xl font-bold">{formatNumberWithCommas(data?.course_count)}</h2>
                    <p>Programs</p>
                </div>
                <div className="bg-blue-500 text-white rounded-md p-4 text-center">
                    <h2 className="text-2xl font-bold">{formatNumberWithCommas(data?.total_specialization)}</h2>
                    <p>Specialization</p>
                </div>
                <div className="bg-blue-500 text-white rounded-md p-4 text-center">
                    <h2 className="text-2xl font-bold">{formatNumberWithCommas(data?.sponsor_count)}</h2>
                    <p>Sponsors</p>
                </div>
                <div className="bg-blue-500 text-white rounded-md p-4 text-center">
                    <h2 className="text-2xl font-bold">{formatNumberWithCommas(data?.sub_sponsor_count)}</h2>
                    <p>Sub Sponsors</p>
                </div>
                <div className="bg-blue-500 text-white rounded-md p-4 text-center">
                    <h2 className="text-2xl font-bold">{formatNumberWithCommas(data?.batch_count)}</h2>
                    <p>Batches</p>
                </div>
                <div className="bg-blue-500 text-white rounded-md p-4 text-center">
                    <h2 className="text-2xl font-bold">{formatNumberWithCommas(data?.applicants)}</h2>
                    <p>Students</p>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-8">
                <div>
                    <h3 className="text-xl font-bold mb-2">Student Status</h3>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'bar',  // Changed from 'column' to 'bar'
                                height: 300,
                            },
                            title: {
                                text: '',
                            },
                            xAxis: {
                                categories: data?.student_status_graph.map((data) => data?.Certification),
                            },
                            yAxis: {
                                title: {
                                    text: 'Enrollments',
                                },
                            },
                            legend: {
                                enabled: true,
                                floating: false,
                                borderWidth: 0,
                                align: 'center',
                                layout: 'horizontal',
                                verticalAlign: 'bottom',
                                padding: 3,
                            },
                            series: [
                                {
                                    name: 'Normal',
                                    data: data?.student_status_graph.map((data) => data?.normal),
                                    color: '#007bff',
                                },
                                {
                                    name: 'Reduced Load',
                                    data: data?.student_status_graph.map((data) => data?.reduced_loads),
                                    color: '#ff6347',
                                },
                            ],
                        }}
                    />

                </div>

                <div>
                    <h3 className="text-xl font-bold mb-2">Sessions </h3>
                    <table className="table-auto border border-gray-300 w-full fixed_header max-h-[300px]">
                        <thead>
                            <tr className="bg-blue-500 text-black">
                                <th className="border border-gray-300 px-4 py-2">Session</th>
                                <th className="border border-gray-300 px-4 py-2">Female</th>
                                <th className="border border-gray-300 px-4 py-2">Male</th>
                                <th className="border border-gray-300 px-4 py-2">Grand Total</th>
                            </tr>
                        </thead>
                        <tbody className='max-h-[260px]'>
                            {data?.module_type_count.map((item) => (
                                <tr key={item.module_type}>
                                    <td className="border border-gray-300 px-4 py-2">{item.module_type}</td>
                                    <td className="border border-gray-300 px-4 py-2">{formatNumberWithCommas(item.female)}</td>
                                    <td className="border border-gray-300 px-4 py-2">{formatNumberWithCommas(item.male)}</td>
                                    <td className="border border-gray-300 px-4 py-2">{formatNumberWithCommas(item.overall)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-8">
                <div>
                    <h3 className="text-xl font-bold mb-2">Course Adoption Based on Gender and Nationality</h3>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'column',
                                height: 300,
                            },
                            title: {
                                text: '',
                            },
                            xAxis: {
                                categories: data?.gender_course_adoption?.map((data) => data?.nationality_gender),
                            },
                            yAxis: {
                                title: {
                                    text: 'Enrollments',
                                },
                            },
                            legend: {
                                enabled: true,
                                floating: false,
                                borderWidth: 0,
                                align: 'center',
                                layout: 'horizontal',
                                verticalAlign: 'bottom',
                                padding: 3,
                            },
                            // series: data?.gender_course_adoption[0].user_count?.map((key, value)=> {
                            //     return {
                            //         name: k,
                            //         data: data.map((v)=> data),
                            //         color: '#007bff',
                            //     }
                            // })
                            series: [
                                {
                                    name: 'Bachelor',
                                    data: data?.gender_course_adoption.map((data) => data?.user_count.Bachelor),
                                    color: '#007bff',
                                },
                                {
                                    name: 'Diploma',
                                    data: data?.gender_course_adoption.map((data) => data?.user_count.Diploma),
                                    color: '#ff6347',
                                },
                                {
                                    name: 'GFP-Fnd',
                                    data: data?.gender_course_adoption.map((data) => data?.user_count['GFP-Fnd']),
                                    color: '#00cc66',
                                },
                                {
                                    name: 'Masters',
                                    data: data?.gender_course_adoption.map((data) => data?.user_count.Masters),
                                    color: '#ff9900',
                                },
                            ],
                        }}
                    />
                </div>

                <div className='h-[350px]'>
                    <h3 className="text-xl font-bold mb-2">Specialization</h3>
                    <div className="overflow-hidden">
                        <table className="table-auto border border-gray-300 w-full fixed_header">
                            <thead>
                                <tr className="bg-blue-500 text-black">
                                    <th className="border border-gray-300 px-4 py-2">Specialization</th>
                                    <th className="border border-gray-300 px-4 py-2">International</th>
                                    <th className="border border-gray-300 px-4 py-2">Oman</th>
                                    <th className="border border-gray-300 px-4 py-2">Grand Total</th>
                                </tr>
                            </thead>
                            <tbody className='max-h-[260px]'>
                                {data?.specialization_count.map((item) => (
                                    <tr key={item.specialisation}>
                                        <td className="border border-gray-300 px-4 py-2">{item.specialisation}</td>
                                        <td className="border border-gray-300 px-4 py-2">{item.International}</td>
                                        <td className="border border-gray-300 px-4 py-2">{item.Oman}</td>
                                        <td className="border border-gray-300 px-4 py-2">{item.overall}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <Suspense fallback={<div>Loading SemesterWiseClass...</div>}>
                <SemesterWiseClass />
            </Suspense>
            <Suspense fallback={<div>Loading AdmissionDashboard...</div>}>
                <AdmissionDashboard />
            </Suspense>
            <Suspense fallback={<div>Loading Top2SponsorsChart...</div>}>
                <Top2SponsorsChart />
            </Suspense>
            <Suspense fallback={<div>Loading StudentsSummaryChart...</div>}>
                <StudentsSummaryChart />
            </Suspense>


        </div>
    );
};

export default StudentCourse;
