import React, { useState } from 'react';
import '../common/common.css';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { response } from '../mapdata/employabilityData';
import { formatNumberWithCommas } from '../utils/utils';
import employbility_banner from '../assets/employbility_banner.png'; // Adjust the path accordingly


import { useGetMecBDEmpQuery, useGetMecBDDashboardQuery } from '../redux/slice/apiSlice';
const Employability = () => {
    const [yearChange, setYearChange] = useState('2023-24');
    const [year2023, setYear2023] = useState('2023-24');
    const [year2022, setYear2022] = useState('');
    const [year2021, setYear2021] = useState('');

    const [enrolmentsOverall, setEnrolmentsOverall] = useState(true);
    const [enrolmentsMinistry, setEnrolmentsMinistry] = useState(false);
    const [enrolmentsDirect, setEnrolmentsDirect] = useState(false);

    const [leadyear2023, setLeadyear2023] = useState('leadyear2023');
    const [leadyear2022, setLeadyear2022] = useState('');
    const [leadyear2021, setLeadyear2021] = useState('');

    const [leadconversion2023, setLeadconversion2023] = useState('2023-24');
    const [leadconversion2022, setLeadconversion2022] = useState('');
    const [leadconversion2021, setLeadconversion2021] = useState('');
    const [employmentTargetValue, setEmploymentTargetValue] = useState('overall')
    const [yearMecBDEmpResponse, setMecBDEmpResponse] = useState(null)
    const [employmentMixYear, setEmploymentMixYear] = useState(null)
    const [corporatesHiringYear, setCorporatesHiringYear] = useState(null)
    const { data: mecBDEmpResponse, error: MecBDEmpError } = useGetMecBDEmpQuery({
        academic_year: yearMecBDEmpResponse
    });
    const { data: mecBDDashboardResponse, error: MecBDDashboardError } = useGetMecBDDashboardQuery({
        emp_provider: employmentTargetValue
    });
    const { data: employmentMixResponse, error: employmentMixError } = useGetMecBDDashboardQuery({
        academic_year: employmentMixYear
    });
    const { data: corporatesHiringResponse, error: corporatesHiringError } = useGetMecBDDashboardQuery({
        academic_year: corporatesHiringYear,
        is_company: 1
    });

    const handleEmploymentTargetValue = (e) => {
        setEmploymentTargetValue(e.target.value)
    }

    const YearChange = (e) => {
        const selectedYear = e.target.value;
        setYearChange(selectedYear);

        if (selectedYear === "2023-24") {
            setYear2023(selectedYear);
            setYear2021('');
            setYear2022('');
        } else if (selectedYear === "2022-23") {
            setYear2022(selectedYear);
            setYear2023('');
            setYear2021('');
        } else if (selectedYear === "2021-22") {
            setYear2021(selectedYear);
            setYear2022('');
            setYear2023('');
        }
    };

    const LearnSourceMixYearChange = (e) => {
        const selectedYear = e.target.value;
        setLeadyear2023(selectedYear === "2023-24" ? selectedYear : '');
        setLeadyear2022(selectedYear === "2022-23" ? selectedYear : '');
        setLeadyear2021(selectedYear === "2021-22" ? selectedYear : '');
    };

    const leadConversionYearChange = (e) => {
        const selectedYear = e.target.value;
        setLeadconversion2023(selectedYear === "2023-24" ? selectedYear : '');
        setLeadconversion2022(selectedYear === "2022-23" ? selectedYear : '');
        setLeadconversion2021(selectedYear === "2021-22" ? selectedYear : '');
    };

    const EnrolmentsButtonClick = (tab) => {
        if (tab === "overall") {
            setEnrolmentsOverall(true);
            setEnrolmentsMinistry(false);
            setEnrolmentsDirect(false);
        } else if (tab === "ministry") {
            setEnrolmentsMinistry(true);
            setEnrolmentsOverall(false);
            setEnrolmentsDirect(false);
        } else {
            setEnrolmentsDirect(true);
            setEnrolmentsOverall(false);
            setEnrolmentsMinistry(false);
        }
    };

    // ... rest of your render function logic using the state variables ...

    return (
        <div className='employabilityWrapper'>
            <section>
                <div className='infoWrapper'>
                    <div className='pageTitle'>
                        <h1>Employability</h1>
                    </div>


                    <div className='pageActions'>
                        <label>Year</label>
                        <select onChange={(e) => setMecBDEmpResponse(e.target.value)} >
                            {mecBDEmpResponse?.data?.academic_year.map(data => <option value={data}>{data}</option>)}
                        </select>
                    </div>
                </div>
            </section>
            <div className="backLink" style={{ backgroundColor: 'white', height: '100%' }}>
                <img
                    src={employbility_banner}
                    alt="Logo"
                    style={{ height: '100%', objectFit: 'fill' }}
                />
            </div>


            <section className='chartWrapper'>
                {/* <div className='left'>
                    <div className='chartheading'>
                        <h3>Employment Targets</h3>
                        <div>
                            <select value={employmentTargetValue} onChange={handleEmploymentTargetValue}>
                                <option value="overall">Overall</option>

                                {
                                    mecBDDashboardResponse?.data?.emp_provider.map((data) => <option value={data}>{data}</option>)
                                }
                            </select>
                           
                        </div>
                    </div>
                    {yearChange && enrolmentsOverall &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: mecBDDashboardResponse?.data?.emp_provider_graph.map((data) => data?.academic_year)
                                },
                                yAxis: {
                                    title: {
                                        text: "Employment Targets"
                                    },
                                },
                                series: [
                                    {
                                        name: 'Achieved',
                                        data: mecBDDashboardResponse?.data?.emp_provider_graph.map((data) => Number(data?.achieved)) || []
                                    },
                                    {
                                        name: 'Target',
                                        data: mecBDDashboardResponse?.data?.emp_provider_graph.map((data) => Number(data?.target)) || []
                                    }
                                ]
                            }}
                        />

                    }

                    {yearChange && enrolmentsMinistry &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ]
                                },
                                yAxis: {
                                    title: {
                                        text: "Employment Targets"
                                    },
                                },
                                series: response[yearChange].enrolmentsMinistry
                            }}
                        />
                    }

                    {yearChange && enrolmentsDirect &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ]
                                },
                                yAxis: {
                                    title: {
                                        text: "Employment Targets"
                                    },
                                },
                                series: response[yearChange].enrolmentsDirect
                            }}
                        />
                    }
                </div> */}
                <div className='middle'>
                    <div className='chartheading'>
                        <h3>Employment Mix</h3>
                        <select onChange={(e) => setEmploymentMixYear(e.target.value)} >
                            {employmentMixResponse?.data?.academic_year.map((data) => <option value={data}>{data}</option>)}

                        </select>
                    </div>

                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'pie',

                            },

                            accessibility: {
                                point: {
                                    valueSuffix: '%'
                                }
                            },
                            tooltip: {
                                pointFormat: '<b>{point.y}%</b>'
                            },
                            title: {
                                text: ''
                            },
                            legend: {
                                enabled: true,
                                floating: false,
                                borderWidth: 0,
                                align: 'center',
                                layout: 'horizontal',
                                verticalAlign: 'bottom',
                                padding: 3,
                                // labelFormatter: function() {
                                //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                // }
                            },
                            plotOptions: {
                                pie: {
                                    shadow: false,
                                    center: ['50%', '50%'],

                                },
                                series: {
                                    allowPointSelect: true,
                                    showInLegend: true,
                                    dataLabels: {
                                        enabled: false,
                                        format: '{point.name}',

                                    },

                                }
                            },


                            series: [{
                                name: 'Functional Domain',
                                colorByPoint: true,
                                data: employmentMixResponse?.data?.domain_target.map((data) => {
                                    return {
                                        "name": data?.functional_domain,
                                        "y": Number(data?.target),

                                    }
                                }),
                            }],
                            // series: response[yearChange].employabilityMixyear2023pie,



                        }}
                    />


                    {leadyear2022 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'pie',

                                },

                                accessibility: {
                                    point: {
                                        valueSuffix: '%'
                                    }
                                },
                                tooltip: {
                                    pointFormat: '<b>{point.y}%</b>'
                                },
                                title: {
                                    text: ''
                                },
                                legend: {
                                    enabled: true,
                                    floating: false,
                                    borderWidth: 0,
                                    align: 'center',
                                    layout: 'horizontal',
                                    verticalAlign: 'bottom',
                                    padding: 3,
                                    // labelFormatter: function() {
                                    //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                    // }
                                },
                                plotOptions: {
                                    pie: {
                                        shadow: false,
                                        center: ['50%', '50%'],

                                    },
                                    series: {
                                        allowPointSelect: true,
                                        showInLegend: true,
                                        dataLabels: {
                                            enabled: false,
                                            format: '{point.name}',

                                        },

                                    }
                                },
                                series: response[yearChange].employabilityMixyear2022pie,



                            }}
                        />
                    }


                    {leadyear2021 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'pie',

                                },

                                accessibility: {
                                    point: {
                                        valueSuffix: '%'
                                    }
                                },
                                tooltip: {
                                    pointFormat: '<b>{point.y}%</b>'
                                },
                                title: {
                                    text: ''
                                },
                                legend: {
                                    enabled: true,
                                    floating: false,
                                    borderWidth: 0,
                                    align: 'center',
                                    layout: 'horizontal',
                                    verticalAlign: 'bottom',
                                    padding: 3,
                                    // labelFormatter: function() {
                                    //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                    // }
                                },
                                plotOptions: {
                                    pie: {
                                        shadow: false,
                                        center: ['50%', '50%'],

                                    },
                                    series: {
                                        allowPointSelect: true,
                                        showInLegend: true,
                                        dataLabels: {
                                            enabled: false,
                                            format: '{point.name}',

                                        },

                                    }
                                },
                                series: response[yearChange].employabilityMixyear2021pie,



                            }}
                        />
                    }

                </div>
                <div className='right'>
                    <div className='chartheading'>
                        <h3>Corporates Hiring</h3>
                        <select onChange={(e) => this.leadConversionYearChange(e)} >
                            <option value={"2023-24"}>2023-24 (Base Year)</option>
                            <option value={"2022-23"}>2022-23</option>
                            <option value={"2021-22"}>2021-22</option>
                        </select>
                    </div>
                    {leadconversion2023 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: {
                                    type: 'column',

                                },
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    type: 'category',
                                    categories: [
                                        'Petroleum Development Oman', 'Oman Oil Company', 'Oman Tel', "Bank Muscat", 'National Bank of Oman', 'Oman Air', 'Sohar Aluminium'
                                    ],
                                    max: 3,
                                    tickLength: 0,
                                    scrollbar: {
                                        enabled: true,
                                        showFull: false
                                    },
                                },
                                yAxis: {
                                    title: {
                                        text: "Corporates Hiring"
                                    },
                                },
                                plotOptions: {
                                    column: {
                                        stacking: 'normal',
                                        dataLabels: {
                                            enabled: false
                                        }
                                    }
                                },

                                series: response[yearChange].conversionyear2023
                            }}
                        />
                    }


                    {leadconversion2022 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: {
                                    type: 'column',

                                },
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    type: 'category',
                                    categories: [
                                        'Petroleum Development Oman', 'Oman Oil Company', 'Oman Tel', "Bank Muscat", 'National Bank of Oman', 'Oman Air', 'Sohar Aluminium'
                                    ],
                                    max: 3,
                                    tickLength: 0,
                                    scrollbar: {
                                        enabled: true,
                                        showFull: false
                                    },
                                },
                                yAxis: {
                                    title: {
                                        text: "Corporates Hiring"
                                    },
                                },
                                plotOptions: {
                                    column: {
                                        stacking: 'normal',
                                        dataLabels: {
                                            enabled: false
                                        }
                                    }
                                },
                                series: response[yearChange].conversionyear2022
                            }}
                        />
                    }


                    {leadconversion2021 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: {
                                    type: 'column',

                                },
                                credits: { enabled: false },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    type: 'category',
                                    categories: [
                                        'Petroleum Development Oman', 'Oman Oil Company', 'Oman Tel', "Bank Muscat", 'National Bank of Oman', 'Oman Air', 'Sohar Aluminium'
                                    ],
                                    max: 3,
                                    tickLength: 0,
                                    scrollbar: {
                                        enabled: true,
                                        showFull: false
                                    },
                                },
                                yAxis: {
                                    title: {
                                        text: "Corporates Hiring"
                                    },
                                },
                                plotOptions: {
                                    column: {
                                        stacking: 'normal',
                                        dataLabels: {
                                            enabled: false
                                        }
                                    }
                                },
                                series: response[yearChange].conversionyear2021
                            }}
                        />
                    }


                </div>

                <div>

                    <div className='cardLeft'>
                        <p>Country Target</p>
                        <h2>{formatNumberWithCommas(mecBDEmpResponse?.data?.country_target)}</h2>
                    </div>
                    <div className='cardRight'>
                        <div className='chartInfo'>
                            <p>Vacancy</p>
                            {
                                mecBDEmpResponse?.data?.country_employment.map((data) => <div className="block">
                                    <p>{data?.country}</p>
                                    <h2>{formatNumberWithCommas(data?.total)}</h2>
                                </div>)
                            }
                        </div>
                        <div className='chartWrapper'>
                            {yearChange && year2023 &&
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                        credits: { enabled: false },
                                        chart: {
                                            type: 'pie',
                                            height: 400,

                                        },

                                        accessibility: {
                                            point: {
                                                valueSuffix: '%'
                                            }
                                        },
                                        tooltip: {
                                            pointFormat: '<b>{point.y}</b>'
                                        },
                                        title: {
                                            text: ''
                                        },
                                        legend: {
                                            enabled: true,
                                            floating: false,
                                            borderWidth: 0,
                                            align: 'center',
                                            layout: 'horizontal',
                                            verticalAlign: 'bottom',
                                            padding: 3,

                                            // labelFormatter: function() {
                                            //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                            // }
                                        },
                                        plotOptions: {
                                            series: {
                                                allowPointSelect: true,
                                                showInLegend: true,
                                                dataLabels: {
                                                    enabled: false,
                                                    // format: '{point.name} {point.y}%',

                                                },

                                            }
                                        },
                                        series: [{
                                            size: '100%',
                                            data: mecBDEmpResponse?.data?.country_employment.map(data => ({
                                                name: data.country,
                                                y: Number(data.total), // Convert total to number
                                            })) || [], // Ensure an empty array if data is undefined
                                        }]



                                    }}
                                />
                            }
                            {yearChange && year2022 &&
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                        credits: { enabled: false },
                                        chart: {
                                            type: 'pie',
                                            height: 400,
                                        },

                                        accessibility: {
                                            point: {
                                                valueSuffix: '%'
                                            }
                                        },
                                        tooltip: {
                                            pointFormat: '<b>{point.y}</b>'
                                        },
                                        title: {
                                            text: ''
                                        },
                                        legend: {
                                            enabled: true,
                                            floating: false,
                                            borderWidth: 0,
                                            align: 'center',
                                            layout: 'horizontal',
                                            verticalAlign: 'bottom',
                                            padding: 3,

                                            // labelFormatter: function() {
                                            //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                            // }
                                        },
                                        plotOptions: {
                                            series: {
                                                allowPointSelect: true,
                                                showInLegend: true,
                                                dataLabels: {
                                                    enabled: false,
                                                    // format: '{point.name} {point.y}%',

                                                },

                                            }
                                        },
                                        series: response[yearChange].seriespie,



                                    }}
                                />
                            }
                            {yearChange && year2021 &&
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                        credits: { enabled: false },
                                        chart: {
                                            type: 'pie',
                                            height: 400,

                                        },

                                        accessibility: {
                                            point: {
                                                valueSuffix: '%'
                                            }
                                        },
                                        tooltip: {
                                            pointFormat: '<b>{point.y}</b>'
                                        },
                                        title: {
                                            text: ''
                                        },
                                        legend: {
                                            enabled: true,
                                            floating: false,
                                            borderWidth: 0,
                                            align: 'center',
                                            layout: 'horizontal',
                                            verticalAlign: 'bottom',
                                            padding: 3,

                                            // labelFormatter: function() {
                                            //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                            // }
                                        },
                                        plotOptions: {
                                            series: {
                                                allowPointSelect: true,
                                                showInLegend: true,
                                                dataLabels: {
                                                    enabled: false,
                                                    // format: '{point.name} {point.y}%',

                                                },

                                            }
                                        },
                                        series: response[yearChange].seriespie,



                                    }}
                                />
                            }
                        </div>
                    </div>

                </div>
            </section>


            <section className='chartWrapper'>
                <div className='left singleChart'>
                    <div className='chartheading'>
                        <h3>Programme Wise Employability</h3>
                    </div>
                    {/* {JSON.stringify(response.year2023.programWiseEnrollment)}              */}
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'column',
                                scrollablePlotArea: {
                                    maxWidth: response[yearChange]?.programWiseEnrollment?.category?.length, // Set the minimum width for scrollable plot area
                                }
                            },
                            title: {
                                text: ''
                            },
                            xAxis: {
                                type: 'category',
                                max: 9,
                                tickLength: 0,
                                scrollbar: {
                                    enabled: true,
                                    showFull: false
                                },
                                crosshair: true,
                                categories: response[yearChange]?.programWiseEnrollment?.category,
                                labels: {
                                    autoRotation: [-25, 0],
                                    rotation: 0,
                                    style: {
                                        fontSize: '10px',
                                    }
                                },
                            },


                            yAxis: {
                                title: {
                                    text: 'Programme Wise Enrolments(10 thousands)'
                                },
                                tickLength: 0,
                                scrollbar: {
                                    enabled: false,
                                    showFull: false
                                },

                            },

                            legend: {
                                enabled: true
                            },

                            plotOptions: {
                                column: {
                                    stacking: 'normal',
                                    dataLabels: {
                                        enabled: false
                                    }
                                }
                            },
                            series: response[yearChange]?.programWiseEnrollment?.data,

                        }}
                    />
                </div>
            </section>








        </div>
    )
};

export default Employability;