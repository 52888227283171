import React, { Component } from 'react';
import '../common/common.css';
// import Highcharts from 'highcharts'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { response } from '../mapdata/year2';


class Revenue extends Component {
    constructor(props) {
        super(props);
        this.state = {
          yearChanges:'',
          year2023:'year2023',
          year2022:'',
          year2021:'',
        }

    }

    componentDidMount() {
      
    }





    componentWillUnmount() {

    }

    
    YearChange = (e) => {
        console.log(e.target.value);
        this.setState({ yearChanges: e.target.value });
        if(e.target.value == "year2023"){
          this.setState({ year2023: e.target.value });
          this.setState({ year2021: ''});
          this.setState({ year2022: ''});
        }
        if(e.target.value == "year2022"){
          this.setState({ year2022: e.target.value });
          this.setState({ year2023: ''});
          this.setState({ year2021: ''});
        }
        if(e.target.value == "year2021"){
          this.setState({ year2021: e.target.value });
          this.setState({ year2022: ''});
          this.setState({ year2023: ''});
        }
    }

    
    
    

    render() {


        return (
            <div className='revenueWrapper'>
                <section>
                    <div className='infoWrapper'>
                        <div className='pageTitle'>
                            <h1>Revenue (Sample Data)</h1>
                        </div>
                    </div>
                </section>
                

                <section className='chartWrapper'>
                    <div className='chartLeft'>
                        <div className='chartheading'>
                            <h3>Revenue Growth Rate</h3>
                        </div>
                        <div className='totalRevenueWrapper'>
                            <p>Total revenue (Base year 2023-24)</p>
                            <h5>{response['revenueData'][0]['total']}</h5>
                            <p>Target : {response['revenueData'][0].target}<span>Increase by {response['revenueData'][0].increaseby} every year</span></p>
                        </div>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                    credits: {enabled: false},
                                    title: {
                                        text: ''
                                    },
                                    legend: {
                                      enabled: false,
                                  },
                                  tooltip:{
                                    pointFormat: '{point.y:,.0f}</b>{point.x}%'
                                 },
                                    xAxis: {
                                        categories: [
                                            '2021-22', '2022-23', '2023-24', '2024-25','2025-26'
                                        ]
                                    },
                                    // series: this.state.enrolmentsOverall ? enrolmentsMinistry : enrolmentsMinistry   
                                    series: response.revenueGrowthRate
                                  }}
                            />
                        
                    </div>
                    <div className='chartRight'>
                        <div className='chartheading'>
                            <h3>Revenue Source Mix</h3>
                            <div>
                                <select defaultValue={this.state.year2023} onChange={(e) => this.YearChange(e)} >
                                    <option value={"year2023"}>2023-24 (Base Year)</option>
                                    <option value={"year2022"}>2022-23</option>
                                    <option value={"year2021"}>2022-22</option>
                                </select>
                            </div>
                        </div>

                        {this.state.year2023 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'pie',
                            
                                },
                            
                                accessibility: {
                                    point: {
                                        valueSuffix: '%'
                                    }
                                },
                                tooltip: {
                                    pointFormat: '<b>{point.y}</b>'
                                },
                                title: {
                                    text: ''
                                },
                                legend: {
                                    enabled: true,
                                    floating: false,
                                    borderWidth: 0,
                                    align: 'right',
                                    layout: 'vertical',
                                    verticalAlign: 'middle',
                                    // labelFormatter: function() {
                                    //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                    // }
                                },
                                plotOptions: {
                                    series: {
                                        allowPointSelect: true,
                                        showInLegend: true,
                                        dataLabels: {
                                            enabled: true,
                                            format: '{point.name}',
                            
                                        },
                            
                                    }
                                },
                                series: response['year2023'].series,
                            
                            
                            
                            }}
                        />
                      }


                      {this.state.year2022 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'pie',
                            
                                },
                            
                                accessibility: {
                                    point: {
                                        valueSuffix: '%'
                                    }
                                },
                                tooltip: {
                                    pointFormat: '<b>{point.y}</b>'
                                },
                                title: {
                                    text: ''
                                },
                                legend: {
                                    enabled: true,
                                    floating: false,
                                    borderWidth: 0,
                                    align: 'right',
                                    layout: 'vertical',
                                    verticalAlign: 'middle',
                                    // labelFormatter: function() {
                                    //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                    // }
                                },
                                plotOptions: {
                                    series: {
                                        allowPointSelect: true,
                                        showInLegend: true,
                                        dataLabels: {
                                            enabled: true,
                                            format: '{point.name}',
                            
                                        },
                            
                                    }
                                },
                                series: response['year2022'].series,
                            
                            
                            
                            }}
                        />
                      }


                      {this.state.year2021 &&
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'pie',
                            
                                },
                            
                                accessibility: {
                                    point: {
                                        valueSuffix: '%'
                                    }
                                },
                                tooltip: {
                                    pointFormat: '<b>{point.y}</b>'
                                },
                                title: {
                                    text: ''
                                },
                                legend: {
                                    enabled: true,
                                    floating: false,
                                    borderWidth: 0,
                                    align: 'right',
                                    layout: 'vertical',
                                    verticalAlign: 'middle',
                                    // labelFormatter: function() {
                                    //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                    // }
                                },
                                plotOptions: {
                                    series: {
                                        allowPointSelect: true,
                                        showInLegend: true,
                                        dataLabels: {
                                            enabled: true,
                                            format: '{point.name}',
                            
                                        },
                            
                                    }
                                },
                                series: response['year2021'].series,
                            
                            
                            
                            }}
                        />
                      }

                       
                        
                    </div>
                </section>


                <section className='chartWrapper'>
                    <div className='chartLeft'>
                        <div className='chartheading'>
                            <h3>EBITDA and PAT</h3>
                        </div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'column'
                                },
                                title: {
                                    text: ''
                                },
                                yAxis: {
                                    text:'EBITDA and PAT'
                                },
                                xAxis: {
                                    categories: [
                                        '2022-23', '2023-24','2024-25', '2025-26','2026-27','2027-28' 
                                    ]
                                },
                                series: response.ebitapat
                            }}
                        />
                    </div>
                    <div className='chartRight'>
                        <div className='chartheading'>
                            <h3>Cost Ratio</h3>
                        </div>

                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'column'
                                },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: [
                                        '2023-24', '2024-25', '2025-26', '2026-27', '2027-28'
                                    ],
                                   
                                },
                                yAxis: {
                                  min: 0,
                                  title: {
                                      text: ''
                                  },
                                  stackLabels: {
                                      enabled: false
                                  }
                              },
                              plotOptions: {
                                column: {
                                    stacking: 'normal',
                                    dataLabels: {
                                        enabled: false
                                    }
                                }
                            },
                                series: response.costratio
                            }}
                        />

                    </div>
                </section>


                <section className='chartWrapper'>
                    <div className='chartRight'>
                        <div className='chartheading'>
                            <div className='block'>
                            <h3>Programme Wise Revenue (Top 10)</h3>
                             <a href="#">View all <i className='fa fa-chevron-right'></i></a>
                            </div>
                        </div>
                        <div className='chatWrapper'>    
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                credits: { enabled: false },
                                chart: {
                                    type: 'column',
                                    scrollablePlotArea: {
                                        maxWidth: response.programWiseRevenue.category.length , // Set the minimum width for scrollable plot area
                                       
                                    }
                                },
                                title: {
                                    text: ''
                                },
                               xAxis: {
                                  type: 'category',
                                  max: 3,
                                  tickLength: 0,
                                  scrollbar: {
                                    enabled: true,
                                    showFull:false
                                  },
                                  crosshair: true,
                                  categories: response.programWiseRevenue.category,
                                  labels: {
                                      autoRotation: [-25,0],
                                      rotation:0,
                                      style: {
                                          fontSize: '10px',
                                     }
                                  },
                              },
                             
                              yAxis: {
                                  title: {
                                      text: 'Programme Wise Revenue (10 thousands)'
                                  },
                                  tickLength: 0,
                                  scrollbar: {
                                    enabled: false,
                                    showFull:false
                                  },
                                  
                              },
                              
                              legend: {
                                enabled: true
                            },

                            plotOptions: {
                                    column: {
                                  dataLabels: {
                                     enabled: false
                                  }
                              }
                          },
                            series: response.programWiseRevenue.data,
                             
                        }}
                        />
                        </div>
                    </div>
                </section>


            </div>
        )
    }
}



export default Revenue;