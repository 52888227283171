import React, { useCallback, useState } from 'react'
import { useCommunityDashboardQuery, useCommunityContributersQuery } from '../../redux/slice/apiSlice'
import CommunityOverview from '../../components/Community/CommunityOverview'
import CategoryWisePostsGraph from '../../components/Community/CategoryWisePostsGraph'
import CategoryWisePostsTable from '../../components/Community/CategoryWisePostsTable'
import TrendingPosts from '../../components/Community/TrendingPosts'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import TrendingReels from '../../components/Community/TrendingReels'
import TopContributor from '../../components/Community/TopContributor'
const Community = () => {
    const [categoryId, setCategoryId] = useState(null)
    const { data: response, error: error } = useCommunityDashboardQuery()
    const { data: responseContributers, error: errorContributers } = useCommunityContributersQuery({
        category_id: categoryId
    })
    const community = response?.data;
    const contributers = responseContributers?.data;
    const handleCategoryChange = useCallback((e) => {
        setCategoryId(e.target.value)
    }, [])
    return (
        <div className='bg-white rounded-sm p-2'>
            <CommunityOverview community={community} />
            <div className="flex my-[100px]">
                <div className='w-[50%] h-[200px]'>
                    <CategoryWisePostsGraph community={community} />
                </div>
                <div className='w-[50%]'>
                    <CategoryWisePostsTable community={community} />
                </div>
            </div>

            <div className="flex my-[100px]">
                <div className='w-[50%] h-[200px]'>
                    <TrendingPosts community={community} />
                </div>
                <div className='w-[50%]'>
                    <TrendingReels community={community} />
                </div>
            </div>

            <div className="flex my-[100px]">
                <div className='w-[50%]'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            chart: {
                                type: 'column',
                            },
                            credits: { enabled: false },
                            title: {
                                text: 'Department Posts',  // Empty title as per your original code
                            },
                            xAxis: {
                                categories: community?.top_department_post?.map((data) => data?.name) || [],
                            },
                            yAxis: {
                                title: {
                                    text: "Count",
                                },
                            },
                            series: [{

                                colorByPoint: true,
                                data: community?.top_department_post?.map(item => ({
                                    name: item?.name || 'Unknown',  // Fallback for item.name
                                    y: Number(item?.post_count) || 0,  // Using `Number` to ensure proper number conversion
                                })) || [],  // Fallback for the data array
                            }],
                        }}
                    />

                </div>
                <div className='w-[50%]'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            chart: {
                                type: 'column',
                            },
                            credits: { enabled: false },
                            title: {
                                text: 'Department Reels',  // Empty title as per your original code
                            },
                            xAxis: {
                                categories: community?.top_department_reel?.map((data) => data?.name) || [],
                            },
                            yAxis: {
                                title: {
                                    text: "Count",
                                },
                            },
                            series: [{

                                colorByPoint: true,
                                data: community?.top_department_reel?.map(item => ({
                                    name: item?.name || 'Unknown',  // Fallback for item.name
                                    y: Number(item?.post_count) || 0,  // Using `Number` to ensure proper number conversion
                                })) || [],  // Fallback for the data array
                            }],
                        }}
                    />
                </div>
            </div>


            <div className="flex my-[100px]">
                <div className='w-[50%]'>
                    <TopContributor title="Top 5 contributors for Post" data={contributers?.top_contributers_post} />

                </div>
                <div className='w-[50%]'>
                    <TopContributor title="Top 5 contributors for Reels" data={contributers?.top_contributers_reels} />
                </div>
            </div>

            <select
                onChange={handleCategoryChange}
                className="my-4 block p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
                {contributers?.category.map((item) => (
                    <option value={item.id} key={item.id}>
                        {item?.title}
                    </option>
                ))}
            </select>


            <div className="tableWrapper">
                <table className="table-auto border-collapse border border-gray-400 fixed_header">
                    <thead>
                        <tr className="">
                            <th className="border p-2 text-bold">Post Title</th>
                            <th className="border p-2 text-bold">People Viewed</th>
                            <th className="border p-2 text-bold">Commented</th>
                            <th className="border p-2 text-bold">Liked</th>
                        </tr>

                    </thead>
                    <tbody className="trhover">
                        {!contributers?.post?.length && (
                            <tr>
                                <td colSpan="100%" className="text-center py-4">
                                    No data
                                </td>
                            </tr>
                        )}

                        {contributers?.post?.map((data) => (
                            <tr>
                                <td className="border p-2">{data?.description?.substring(0, 100)}...</td>
                                <td className="border p-2">{data?.view_count}</td>
                                <td className="border p-2">{data?.comment_count}</td>
                                <td className="border p-2">{data?.like_count}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>






        </div>
    )
}

export default Community