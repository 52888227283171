import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React from 'react';
import { useCrmQuery } from '../../redux/slice/apiSlice';
import { formatNumberWithCommas } from '../../utils/utils';
const ProgramSponsor = () => {

  const { data: crmResponse, error: crmError } = useCrmQuery()
  
  const crm = crmResponse?.data;

  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
        <div className="bg-blue-500 text-white rounded-md p-4 text-center">
          <h2 className="text-2xl font-bold">{formatNumberWithCommas(crm?.channel)}</h2>
          <p>Channels</p>
        </div>
        <div className="bg-blue-500 text-white rounded-md p-4 text-center">
          <h2 className="text-2xl font-bold">{formatNumberWithCommas(crm?.program)}</h2>
          <p>Programs</p>
        </div>
        <div className="bg-blue-500 text-white rounded-md p-4 text-center">
          <h2 className="text-2xl font-bold">{formatNumberWithCommas(crm?.source)}</h2>
          <p>UTM Sources</p>
        </div>
        <div className="bg-blue-500 text-white rounded-md p-4 text-center">
          <h2 className="text-2xl font-bold">{formatNumberWithCommas(crm?.applicant)}</h2>
           {/* { <h2 className="text-2xl font-bold">{2023}</h2> } */}
          <p>Leads</p>
        </div>

      </div>


      <div className="revenueWrapper my-2">
        <section className='chartWrapper'>
          <div className='chartLeft'>
            <div className='chartheading'>
              <div className='block'>
                <h3>Program wise Status</h3>

              </div>
            </div>
            <div className='chatWrapper'>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'column'
                  },
                  title: {
                    text: ''
                  },
                  subtitle: {
                    text: ''
                  },
                  xAxis: {
                    categories: crm?.program_status.map((data) => data?.status),
                    title: {
                      text: 'Sponsorship Type'
                    }
                  },
                  yAxis: {
                    min: 0,
                    
                    title: {
                      text: 'Number of Students'
                    }
                  },
                  tooltip: {
                    pointFormat: '{series.name}: <b>{point.y}</b>'
                  },
                  plotOptions: {
                    column: {
                      pointPadding: 0.2,
                      borderWidth: 0
                    }
                  },

                  series: [{
                    name: 'GFP',
                    data: crm?.program_status.map((data) => data?.user_count['GFP'])
                  },
                  {
                    name: 'Master',
                    data: crm?.program_status.map((data) => data?.user_count['Master'])
                  },
                  {
                    name: 'Bachelor',
                    data: crm?.program_status.map((data) => data?.user_count['Bachelor'])
                  },
                  {
                    name: 'Diploma',
                    data: crm?.program_status.map((data) => data?.user_count['Diploma'])
                  },
                  {
                    name: 'other',
                    data: crm?.program_status.map((data) =>
                      Object.entries(data?.user_count)
                        .filter(([key, value]) => !isNaN(Number(key)) && typeof value === 'number')
                        .reduce((acc, [key, value]) => acc + value, data?.user_count['other'] || 0)
                    )
                  }
                  ]
                }}
              />
            </div>
          </div>

          <div className='chartRight'>
            <div className='chartheading'>
              <div className='block'>
                <h3>Application in Different Programs</h3>

              </div>
            </div>
            <div className='chatWrapper'>
              <HighchartsReact highcharts={Highcharts} options={{
                title: {
                  text: "APPLICATIONS",
                },
                tooltip: {
                  pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
                },
                plotOptions: {
                  pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                      enabled: true,
                      format: "{point.name}: {point.y}",
                    },
                    showInLegend: true,
                  },
                },

                series: [
                  {
                    name: "Applications",
                    type: "pie",
                    data: crm?.program_applicants?.map((item) => ({
                      name: `${item.program}`,
                      y: item.user_count,
                    })),
                  },
                ],
              }} />
            </div>
          </div>


        </section>
      </div>

      <table className='border-collapse border border-gray-400 w-full my-2 fixed_header' >
        <thead>
          <tr>
            <th className="border border-gray-400 p-2">Status</th>
            <th className="border border-gray-400 p-2">GFP</th>
            <th className="border border-gray-400 p-2">Master</th>
            <th className="border border-gray-400 p-2">Bachelor</th>
            <th className="border border-gray-400 p-2">Diploma</th>
            <th className="border border-gray-400 p-2">Other</th>
          </tr>
        </thead>
        <tbody className='max-h-[300px]'>

          {crm?.program_status.map(data =>
            <tr>
              <td className="border border-gray-400 p-2">{data.status}</td>
              <td className="border border-gray-400 p-2">{data?.user_count['GFP'] || 0}</td>
              <td className="border border-gray-400 p-2">{data?.user_count['Master'] || 0}</td>
              <td className="border border-gray-400 p-2">{data?.user_count['Bachelor'] || 0}</td>
              <td className="border border-gray-400 p-2">{data?.user_count['Diploma'] || 0}</td>
              <td className="border border-gray-400 p-2">{Object.entries(data?.user_count)
                .filter(([key, value]) => !isNaN(Number(key)) && typeof value === 'number')
                .reduce((acc, [key, value]) => acc + value, 0)}
              </td>
            </tr>
          )}
        </tbody>
      </table>


    </div>
  )
}

export default ProgramSponsor