// src/slice/pageTitleSlice.js
import { createSlice } from '@reduxjs/toolkit';

const pageTitleSlice = createSlice({
  name: 'pageTitle',
  initialState: {
    title: '',
  },
  reducers: {
    setTitle(state, action) {
      state.title = action.payload;
    },
  },
});

export const { setTitle } = pageTitleSlice.actions;
export default pageTitleSlice.reducer;
