import React, { useState } from 'react';
import { useProgramSponsorQuery } from '../../redux/slice/apiSlice';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
const ProgramsWithSponsors = () => {
    const [selectedSponser, setSelecteSponser] = useState(null)
    const { data: programSponsorResponse, error: error } = useProgramSponsorQuery({
        sponsor: selectedSponser
    })
    const programSponsor = programSponsorResponse?.data;
    return (
        <div>
            <h3 className='font-bold text-xl'>Programs With Sponsors</h3>
            <div className="revenueWrapper my-2">
                <section className='chartWrapper'>
                    <div className='chartLeft'>
                      


                        <div className='chatWrapper'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                    chart: {
                                        type: 'bar',
                                    },
                                    title: {
                                        text: 'Direct, MoHERI and Others',
                                    },
                                    subtitle: {
                                        text: '',
                                    },
                                    xAxis: {
                                        categories: programSponsor?.program_data
                                            .filter((data) => isNaN(Number(data?.program_short_code))) // Adjusted filter
                                            .map((data) => data?.program_short_code),
                                        title: {
                                            text: 'Sponsorship Type',
                                        },
                                    },
                                    yAxis: {
                                        min: 0,
                                        title: {
                                            text: 'Number of Students',
                                        },
                                    },
                                    tooltip: {
                                        pointFormat: '{series.name}: <b>{point.y}</b>',
                                    },
                                    plotOptions: {
                                        column: {
                                            pointPadding: 0.2,
                                            borderWidth: 0,
                                        },
                                    },
                                    series: [
                                        {
                                            name: 'Direct',
                                            data: programSponsor?.program_data.filter((data) => isNaN(Number(data?.program_short_code))).map((data) => data?.user_count?.Direct || 0), // Ensuring fallback to 0
                                        },
                                        {
                                            name: 'MoHERI',
                                            data: programSponsor?.program_data.filter((data) => isNaN(Number(data?.program_short_code))).map((data) => data?.user_count?.MoHERI || 0), // Ensuring fallback to 0
                                        },
                                        {
                                            name: 'Others',
                                            data: programSponsor?.program_data.filter((data) => isNaN(Number(data?.program_short_code))).map((data) => data?.user_count?.MoHERI || 0), // Ensuring fallback to 0
                                        },
                                        // {
                                        //     name: 'Others',
                                        //     data: programSponsor?.program_data.map((data) =>
                                        //         Object.entries(data?.user_count || {})
                                        //             .filter(([key]) => !['Direct', 'MoHERI'].includes(key)) // Exclude Direct and MoHERI
                                        //             .reduce((acc, [, value]) => acc + value, 0) // Sum other sponsorships
                                        //     ),
                                        // },
                                    ],
                                }}
                            />


                        </div>
                    </div>

                    <div className='chartRight'>
                  

                        <div className='chatWrapper'>
                            
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                    chart: {
                                        type: 'bar', // Bar chart type
                                    },
                                    title: {
                                        text: "Applications from UTM Sources",
                                    },
                                    tooltip: {
                                        pointFormat: "{series.name}: <b>{point.y}</b>",
                                    },
                                    plotOptions: {
                                        bar: { // Specific plot options for bar chart
                                            dataLabels: {
                                                enabled: true,
                                            },
                                        },
                                    },
                                    xAxis: {
                                        categories: programSponsor?.source_user_count?.map(item => item.source), // Add x-axis categories
                                        title: {
                                            text: 'Source',
                                        },
                                    },
                                    yAxis: {
                                        min: 0,
                                        title: {
                                            text: 'User Count',
                                        },
                                    },
                                    series: [
                                        {
                                            name: "Applications",
                                            data: programSponsor?.source_user_count?.map(item => item.user_count), // Bar chart data structure
                                        },
                                    ],
                                }}
                            />

                        </div>
                    </div>


                </section>



                <section className='chartWrapper'>
                    <div className='chartLeft'>
                        <div className='chatWrapper'>
                        <h1 className="font-semibold text-lg text-center mb-2">Sponsorship Type</h1>
                            <select className='my-2' onChange={(e) => setSelecteSponser(e.target.value)}>
                                {programSponsor?.sponsors.map((data) => <option value={data}>{data}</option>)}
                            </select>
                            

                            <table className="table-auto border border-gray-300 w-full fixed_header max-h-[300px]">
                                <thead>
                                    <tr>
                                        <th>Sponsorship Type</th>
                                        <th>Direct</th>
                                        <th>MoHERI</th>
                                        <th>Others</th>
                                    </tr>
                                </thead>
                                <tbody className='max-h-[260px]'>
                                    {programSponsor?.program_data
                                        .filter((data) => isNaN(Number(data?.program_short_code))) // Adjusted filter
                                        .map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.program_short_code}</td>
                                                <td>{data?.user_count?.Direct || 0}</td>
                                                <td>{data?.user_count?.MoHERI || 0}</td>
                                                <td>{data?.user_count?.Others || 0}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='chartRight'>
                        <div className='chatWrapper'>
                        <h1 className="font-semibold text-lg text-center mb-2">Application Source</h1>

                            <table className="table-auto border border-gray-300 w-full fixed_header max-h-[300px]">
                                <thead>
                                    <tr>
                                        <th>Source</th>
                                        <th>Applications</th>
                                    </tr>
                                </thead>
                                <tbody className='max-h-[220px]'>
                                    {programSponsor?.source_user_count.slice(0,10)?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.source}</td>
                                            <td>{item.user_count}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

            </div>



        </div>
    )
}

export default ProgramsWithSponsors